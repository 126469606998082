import {useEffect, useState} from 'react'
import {convertShortTimestamp, getGlobalMatchingStats} from '../../../helper/functions';
import { CircularProgress } from '@mui/material';
import Button from '../../Generics/Button';

const GlobalMatching = () => {

    const [stats, setStats] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getHistory();
    }, []);

    const getHistory = async () => {
        try{
            const result = await getGlobalMatchingStats();
            setStats(result.data);
        } catch(e) {
            console.log(e.message);
        }
        return;
    };

    const startGlobalMatching = async () => {
        
    };
    

    return (
        <div className='flex flex-col w-full justify-center items-center mx-5 mt-12'>

            {!isLoading && 
            <div className='flex flex-col w-full justify-center items-center'>
                <p className='text-allimmoDark text-lg'>Global Matchings bisher</p>
                <p className='font-bold text-lg'>{stats?.number}</p>
                <p className='text-allimmoDark text-lg'>zuletzt</p>
                <p className='font-bold text-lg mb-8'>{stats?.last}</p>
                <Button text='Global Matching Starten' clickFunction={getHistory}/>
            </div>}
            {isLoading && <CircularProgress size={30}/>}
        </div>
    )
}

export default GlobalMatching
