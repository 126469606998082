import { useEffect, useState } from 'react'
import { Checkbox, CircularProgress, FormControlLabel, Step } from '@mui/material';
import { autoContactExisting, deleteSingleUser, getUserCredits, getUserSettings } from '../../../helper/functions';
import validator from 'validator';
import { t } from 'i18next';
import Input from '../../Generics/Input';

const ActivateAutoContact = () => {

    const [inputData, setInputData] = useState({
        userID: "",
        email: "",
    });
    const [depthSettings, setDepthSettings] = useState({
        genau: false,
        normal: false,
        ungenau: false,
    })
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState();
    const [step, setStep] = useState(0);
    const [userCredits, setUserCredits] = useState(0);
    const [creditsLimit, setCreditsLimit] = useState(0);
    const [userSettings, setUserSettings] = useState({});

    const isButtonDisabled = (inputData.userID.trim() === "" && inputData.email.trim() === "") || (inputData.email.trim().length > 0 && !validator.isEmail(inputData.email));
    const isActivateButtonDisabled = userCredits === 0 || userSettings.autoContact === true || (!depthSettings.genau && !depthSettings.ungenau && !depthSettings.normal) || creditsLimit >= userCredits;
    
    useEffect(() => {
        if(result?.message === true) {
            setInputData({
                userID: "",
                email: "",
            });
            
            console.log(result);
        }
        setIsLoading(false);
    }, [result]);

    const activateAutoContact = async () => {
        setIsLoading(true);
        try {
            await autoContactExisting({
                ...depthSettings,
                creditsLimit: creditsLimit,
            });
            setResult(result.data);
            
        } catch (error) {
            console.log(error.message);
        }
        setIsLoading(false);
        return;
    };

    const handleAutoContactDepth = (depth) => {
        setDepthSettings({...depthSettings, [depth]: !depthSettings[depth]});
        return;
    };

    const getCredits = async () => {
        const result = await getUserCredits({email: inputData.email, uid: inputData.userID});
        setUserCredits(result.data.credits);
        setInputData({...inputData, email: result.data.email});
        return;
    };

    const getSettings = async () => {
        const settings = await getUserSettings({email: inputData.email, uid: inputData.userID});
        setUserSettings(settings.data.settings);
        return;
    };

    const handleStart = async () => {
        setIsLoading(true);
        try {
            await getCredits();
            await getSettings();
            setStep(1);
        } catch (error) {
            console.log(error.message);
        }
        setIsLoading(false);
    };

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            {(!result && !isLoading && step === 0) && <p className='italic text-s mt-12 mb-10 w-600 text-center'>Hier kannst du nach Eingabe der Email Adresse oder der User ID den Auto-Contact Modus bei diesem User aktivieren. Voraussetzung ist, der User hat genug Credits. Alle bestehenden Matches werden nach der Aktivierung auch sofort kontaktiert.</p>}

            {(!result && !isLoading) &&
            <div className={`w-full flex flex-col justify-center items-center ${isLoading ? "hidden" : "block"}`}>
                {step === 0 && <input disabled={inputData.email !== ""} value={inputData.userID} onChange={(e) => setInputData({...inputData, userID: e.target.value})} type="text" placeholder="UserID eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>}
                {step === 0 && <p className='italic my-3 font-semibold'>ODER</p>}
                {step === 0 && <input disabled={inputData.userID !== ""} value={inputData.email} onChange={(e) => setInputData({...inputData, email: e.target.value})} type="text" placeholder="Email-Adresse eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>}

                {step === 1 && <div className='flex flex-col items-center justify-center mt-5 mb-1 italic bg-white px-5 py-2 shadow-lg border border-allimmoDark/50 rounded'>
                    <p className='font-bold text-lg mb-1 opacity-80'>{inputData.email}</p>
                    <p className={`${userCredits === 0 ? "animate-pulse text-red-400 font-bold" : "text-green-500"}`}>Der User hat {userCredits.toLocaleString()} Credits</p>
                    <p className={`${userSettings?.autoContact ? "animate-pulse text-red-400 font-bold" : "text-green-500"}`}>Auto-Contact ist {userSettings?.autoContact ? "aktiviert" : "deaktiviert"}</p>
                </div>}

                {(step === 1 && userCredits > 0 && !userSettings.autoContact) &&
                <div className=' flex flex-col items-center justify-center'>
                    <p className='mt-3 mb-1'>Welche Suchprofilgenauigkeit soll automatisch kontaktiert werden?</p>
                    <div className={`flex flex-row gap-0.5 ssm:gap-5`}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    disableRipple
                                    disableTouchRipple
                                    value={depthSettings?.genau ?? false}
                                    checked={depthSettings?.genau ?? false}
                                    onChange={() => handleAutoContactDepth("genau")}
                                    />
                                }
                                label={
                                    <p className="text-sm sm:text-base">
                                    {t("DepthStufen.genau")}
                                    </p>
                                }
                                />
                    
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    disableRipple
                                    disableTouchRipple
                                    value={depthSettings?.normal ?? false}
                                    checked={depthSettings?.normal ?? false}
                                    onChange={() => handleAutoContactDepth("normal")}
                                    />
                                }
                                label={
                                    <p className="text-sm sm:text-base">
                                    {t("DepthStufen.normal")}
                                    </p>
                                }
                                />
                    
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    disableRipple
                                    disableTouchRipple
                                    value={depthSettings?.ungenau ?? false}
                                    checked={depthSettings?.ungenau ?? false}
                                    onChange={() => handleAutoContactDepth("ungenau")}
                                    />
                                }
                                label={
                                    <p className="text-sm sm:text-base">
                                    {t("DepthStufen.ungenau")}
                                    </p>
                                }
                                />
                    </div>
                    <p className='mt-3 mb-1'>Welches Credits Limit soll gelten?</p>
                    <input className='text-center border h-8 w-[80px]' type='number' value={creditsLimit} onChange={(e) => setCreditsLimit(e.target.value)}/>
                </div>}
            </div>}

            {(result && !isLoading && result.success) &&
            <div className='flex flex-col items-center justify-center mt-10 w-full'>
                {result.success &&
                <div className='flex flex-col items-center justify-center w-1/3'>
                    <p className='text-2xl underline'>User erfolgreich gelöscht</p>
                    <p className='italic text-center w-full mt-2'>Zusammenfassung</p>
                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Email Adresse</p>
                        <p className='italic text-xs'>{result.summary.dEmail}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>User ID</p>
                        <p className='italic text-xs'>{result.summary.dUserId}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Objekte</p>
                        <p className='italic text-xs'>{result.summary.dObjects}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Suchen</p>
                        <p className='italic text-xs'>{result.summary.dSearches}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Kontakte</p>
                        <p className='italic text-xs'>{result.summary.dContacts}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Chats</p>
                        <p className='italic text-xs'>{result.summary.dChats}</p>
                    </div>

                    <div className='flex flex-row items-center justify-between mt-2 border-b w-full'>
                        <p className='italic text-xs'>Matches</p>
                        <p className='italic text-xs'>{result.summary.dObjectsMatches + result.summary.dSearchesMatches}</p>
                    </div>
                    
                    <div className='flex flex-row gap-3 justify-center items-center'>
                        
                    </div>
                </div>}

                {!result.success &&
                <div>
                    <p className='text-2xl underline'>Ein Fehler ist aufgetreten</p>
                    <p className='italic text-xs'>{result.message}</p>
                </div>}
            </div>}

            {(result && !isLoading && result.message === "no-user-found") &&
            <div className='flex flex-col justify-center items-center mt-10'>
                <p className='text-center text-red-600 text-xl bg-red-100'>Kein User mit diesen Daten in der Datenbank gefunden</p>
                {inputData.email !== "" && <p className=''>Email: {inputData.email}</p>}
                {inputData.userID !== "" && <p className=''>User-ID: {inputData.userID}</p>}
            </div>}

            {isLoading && <CircularProgress className='mt-10' size={60} />}

            {!isLoading &&
            <div className='flex flex-row gap-3 mt-8'>
                {step === 0 && <button onClick={handleStart}  disabled={isButtonDisabled} className={`border border-allimmoDark px-3 py-1 rounded ${isButtonDisabled ? "opacity-50" : "hover:bg-blue-300 bg-blue-200 cursor-pointer"}`}>Start</button>}
                {(!result && step === 1) && <button onClick={activateAutoContact} disabled={isActivateButtonDisabled} className={`border border-allimmoDark px-3 py-1 rounded ${isActivateButtonDisabled ? "opacity-50" : "hover:bg-blue-300 bg-blue-200 cursor-pointer"}`}>Auto-Kontakt Aktivieren</button>}
                {step !== 0 && <button onClick={() => setStep(0)} className={`border border-allimmoDark px-3 py-1 rounded bg-blue-200 cursor-pointer hover:bg-blue-300`}>Neu Beginnen</button>}
            </div>}
        </div>
    )
}

export default ActivateAutoContact
