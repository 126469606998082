import { t } from "i18next";
import { useEffect, useState } from "react";
import { convertTimestamp } from "../helper/functions";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "../helper/icons";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { auth, firestore } from "../firebase.config";
import { CircularProgress } from "@mui/material";

const Affiliate = () => {
  const [data, setData] = useState();
  const [recruits, setRecruits] = useState([]);
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // sum off all recruits rewarded credits
  const totalRewards = recruits.reduce((acc, curr) => acc + curr?.rewardedCredits, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAffiliateData();
  }, []);

  const getAffiliateData = async () => {
    try {
      setIsLoading(true);
      const affiliateData = await getDoc(doc(firestore, "affiliates", auth.currentUser.uid));
      const recruits = await getDocs(collection(firestore, "affiliates", auth.currentUser.uid, "recruits"));
      setData(affiliateData.data());
      setRecruits(recruits.docs.map((doc) => ({...doc.data(), id: doc.id})));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col w-full px-2 max-w-650 items-center">
      <p className="text-2xl text-center w-full">{t('Affiliate.Dashboard')}</p>
      <p className="text-center w-full text-sm mt-1">Erhalte 10% der gekauften Credits Deiner geworbene Nutzer als Provision.</p>
      <p className="text-center w-full text-sm -mt-0.5">Gutgeschrieben wird ab 10 gekauften Credits des geworbenen Nutzers - entspricht 1 Credit an Provision.</p>
      <p className="text-center w-full text-sm -mt-0.5">Die Gutschrift erfolgt 15 Tage nach dem Kauf direkt auf dein Credit Guthaben.</p>
      {isLoading && <CircularProgress size={60} className="mt-10"/>}
      {!isLoading &&
      <div className="my-8 flex flex-col justify-center items-center">
        <div className="flex flex-col">
          <p className="text-xs italic text-center w-full">{t('Affiliate.Code')}</p>
          <p className="text-3xl text-allimmoDark font-bold p-1 bg-allimmoDark/20 select-text">{data?.code}</p>
          <CopyToClipboard text={data?.code}>
            <div>{!copied && <Copy size={20} onClick={() => setCopied(true)} className="text-allimmoDark cursor-pointer w-full mt-1"/>}</div>
          </CopyToClipboard>
        </div>
        {copied && <p className="text-green-500 text-xs mt-1">{t('Affiliate.CodeKopiert')}</p>}
      </div>}

      {!isLoading &&
      <div className="flex flex-col gap-2 w-full max-w-450">
        {/* <p className="text-center mt-3 text-lg">{t('Affiliate.GeworbeneMitglieder')}</p>
        <p className="text-center text-3xl text-allimmoDark font-bold">{recruits?.length}</p> */}

        <div className="flex flex-row items-center justify-between w-full max-w-600 px-2 py-1 shadow-[0px_0px_8px_-4px_#8a8a8a] border rounded border-allimmoDark bg-white">
          <div className="w-full h-20 border-r flex flex-col justify-center items-center gap-0.5">
            <p className="text-3xl font-bold">{recruits?.length}</p>
            <p className="">bisher geworben</p>
          </div>

          <div className="w-full h-20 flex flex-col justify-center items-center">
            <p className="text-3xl font-bold">{totalRewards}</p>
            <p className="">credits verdient</p>
          </div>
        </div>
        {recruits?.length > 0 && <p className="italic mt-4 text-sm w-full text-center">{t('Affiliate.InfoText')}</p>}
        <div className="flex flex-col gap-1 w-full mt-1">
          {recruits?.map((r, i) => {
            return (
              <Recruit key={i} data={r} index={i}/>
            )
          })}
        </div>
      </div>}
    </div>
  )
};

const Recruit = ({data, index}) => {
  return (
    <div className="flex flex-row items-center w-full px-2 py-1 border rounded border-allimmoDark bg-white">
      {/* <p className="grow italic text-sm">{data?.id.substr(0, 10) + "..."}</p> */}
      <p className="font-bold mr-3">{index + 1}</p>
      <p className="grow">{convertTimestamp(data?.created)}</p>
      <div className="flex flex-row items-baseline justify-end w-full gap-1 font-bold">
        <p className="">{data?.rewardedCredits}</p>
        <p className="text-xs opacity-30">{data?.remainder}</p>
      </div>
    </div>
  )
};

export default Affiliate;
