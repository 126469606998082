import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFirestoreEmulator, enablePersistentCacheIndexAutoCreation, getFirestore, initializeFirestore, memoryLocalCache, persistentLocalCache } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCcQ-NEn33BX7O8WgTYJvyLIxHtJ7YyIpI",
  authDomain: "www.allimmomatch.com",
  //authDomain: "move-58c8d--preview-z4azg9wu.web.app",
  projectId: "move-58c8d",
  storageBucket: "move-58c8d.appspot.com",
  messagingSenderId: "806345040065",
  appId: "1:806345040065:web:300aedba17f45ef2acf440",
  measurementId: "G-N97SSX26CN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const firestore = getFirestore(app);
export const firestore = initializeFirestore(app, {
  localCache: memoryLocalCache(),
});
export const firestoreDev = getFirestore(app, 'development');
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app, "europe-west3");
export const storage = getStorage(app);

// connectFunctionsEmulator(functions, "127.0.0.1", 5001);
// connectFirestoreEmulator(firestore, '127.0.0.1', 8080);