import { createContext, useContext, useEffect, useState } from "react";
import { deleteSingleObject, deleteSingleSearch } from "../helper/functions";
import { useStateContext } from "./ContextProvider";
import { useSelectionContext } from "./SelectionProvider";
import { useNavigationContext } from "./NavigationProvider";

const DeletionContext = createContext();

export const DeletionProvider = ({ children }) => {

    const { customerObjects, customerSearches, setIsLoading } = useStateContext();
    // const { handleMenuState, menuState } = useNavigationContext();
    const { setObjectToOpen } = useSelectionContext();
    
    const [deletedObject, setDeletedObject] = useState("");
    const [deletedSearch, setDeletedSearch] = useState("");
    const [objectDeleteConfirmOpen, setObjectDeleteConfirmOpen] = useState(false);
    const [searchDeleteConfirmOpen, setSearchDeleteConfirmOpen] = useState(false);
    const [objectDeletionSurveyOpen, setObjectDeletionSurveyOpen] = useState(false);
    const [searchDeletionSurveyOpen, setSearchDeletionSurveyOpen] = useState(false);

    const handleMenuState = (clicked) => {};
    const menuState = "";

    useEffect(() => {
      if(deletedObject !== "") {
        setObjectDeleteConfirmOpen(true);
      }
    }, [deletedObject]);

    useEffect(() => {
      if(!objectDeleteConfirmOpen) {
        setDeletedObject("");
      }
    }, [objectDeleteConfirmOpen]);
    

    const deleteObject = async (cause) => {
        if(deletedObject !== "") {
            setObjectToOpen();
            setIsLoading(true);
            setObjectDeleteConfirmOpen(false);
            await deleteSingleObject({objectId: deletedObject, reason: cause});
            setIsLoading(false);
        }
        if(customerObjects.length > 0  && menuState !== "objects") {
            handleMenuState("objects");
        } else {
            handleMenuState("objects");
        }
    };

    const deleteSearch = async(cause) => {
        if(!["", undefined].includes(deletedSearch)) {
            setIsLoading(true);
            setSearchDeleteConfirmOpen(false);
            await deleteSingleSearch({searchId: deletedSearch, reason: cause});
            setIsLoading(false);
        }
        if(customerSearches.length > 0 && menuState !== "searches") {
            handleMenuState("searches");
        } else {
            handleMenuState("searches");
        }
    };
 
    return (
        <DeletionContext.Provider
            value={{ 
                deletedObject,
                objectDeleteConfirmOpen,
                deletedSearch,
                searchDeleteConfirmOpen,
                objectDeletionSurveyOpen,
                searchDeletionSurveyOpen,
                setSearchDeletionSurveyOpen,
                setObjectDeletionSurveyOpen,
                deleteObject,
                deleteSearch,
                setSearchDeleteConfirmOpen,
                setObjectDeleteConfirmOpen,
                setDeletedSearch,
                setDeletedObject
            }}>
                {children}
        </DeletionContext.Provider>
    )
}

export const useDeletionContext = () => useContext(DeletionContext);