import { useEffect, useState } from 'react';
import Address from '../components/Objects/Creation/Address';
import HouseType from '../components/Objects/Creation/HouseType';
import ObjectMap from '../components/Objects/Creation/ObjectMap';
import Price from '../components/Objects/Creation/Price';
import Type from '../components/Objects/Creation/Type';
import { useCreationContext } from '../contexts/CreationProvider';
import Deal from '../components/Objects/Creation/Deal';
import Condition from '../components/Objects/Creation/Condition';
import Size from '../components/Objects/Creation/Size';
import Facilities from '../components/Objects/Creation/Facilities';
import Heating from '../components/Objects/Creation/Heating';
import ConstructionYear from '../components/Objects/Creation/ConstructionYear';
import Style from '../components/Objects/Creation/Style';
import OpenAreas from '../components/Objects/Creation/OpenAreas';
import Summary from '../components/Objects/Creation/Summary';
import { objectCreation, updateFirestoreDoc } from '../helper/functions';
import { useStateContext } from '../contexts/ContextProvider';
import Expose from '../components/Objects/Creation/Expose';
import Pics from '../components/Objects/Creation/Pics';
import Floor from '../components/Objects/Creation/Floor';
import Furnished from '../components/Objects/Creation/Furnished';
import OtherInfos from '../components/Objects/Creation/OtherInfos';
import State from '../components/Objects/Creation/State';
import Inspection from '../components/Objects/Creation/Inspection';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../firebase.config';
import { t } from 'i18next';
import Notes from '../components/Objects/Creation/Notes';
import Units from '../components/Objects/Creation/Units';
import UnitsState from '../components/Objects/Creation/UnitsState';
import UnitsCondition from '../components/Objects/Creation/UnitsCondition';
import OpenAreasMulti from '../components/Objects/Creation/OpenAreasMulti';
import { useLoadingContext } from '../contexts/LoadingProvider';
import LandProperties from '../components/Objects/Creation/LandProperties';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PresentIndustry from '../components/Objects/Creation/PresentIndustry';
import Licensing from '../components/Objects/Creation/Licensing';
import CommercialVacancy from '../components/Objects/Creation/CommercialVacancy';
import CommercialType from '../components/Objects/Creation/CommercialType';
import GaragePrice from '../components/Objects/Creation/GaragePrice';
import CreationProgressbar from '../components/CreationProgressbar';

const NewObject = () => {

  const { objectData, objectComissionContractFile } = useCreationContext();
  const { setIsCreating } = useLoadingContext();
  const { setIsLoading } = useStateContext();

  const [stage, setStage] = useState("type");
  const [createdObjectID, setCreatedObjectID] = useState();
  const [progressPercent, setProgressPercent] = useState(0);
  const [showProgress, setShowProgress] = useState(true);

  const createObjectRoute = [
    {
      id: 1,
      stage: "type",
      deal: "all",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 2,
      stage: "presentIndustry",
      deal: "all",
      usage: ["commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 3,
      stage: "licensing",
      deal: "all",
      usage: ["commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 4,
      stage: "commercialType",
      deal: "all",
      usage: ["commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 5,
      stage: "deal",
      deal: "all",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 6,
      stage: "housetype",
      deal: "all",
      usage: ["house"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 7,
      deal: "all",
      stage: "location",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 8,
      deal: "all",
      stage: "address",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 9,
      deal: "all",
      stage: "style",
      usage: ["multi", "apartment", "commercial"],
      commercialUsage: ["apartment", "building", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 10,
      stage: "condition",
      deal: "all",
      usage: ["multi", "apartment", "house", "commercial"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 11,
      deal: "all",
      stage: "units",
      usage: ["multi", "commercial"],
      commercialUsage: ["building"],
      areaUsage: "all"
    },
    {
      id: 12,
      deal: "sale",
      stage: "unitsState",
      usage: ["multi", "commercial"],
      commercialUsage: ["building", "hall"],
      areaUsage: "all"
    },
    {
      id: 13,
      deal: "all",
      stage: "unitsCondition",
      usage: ["multi", "commercial"],
      commercialUsage: ["building"],
      areaUsage: "all"
    },
    {
      id: 14,
      deal: "sale",
      stage: "state",
      usage: ["house", "apartment", "commercial"],
      commercialUsage: ["apartment", "hall", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 15,
      deal: "sale",
      stage: "commercialVacancy",
      usage: ["commercial"],
      commercialUsage: ["building", "hall"],
      areaUsage: "all"
    },
    {
      id: 16,
      deal: "all",
      stage: "landProps",
      usage: ["land"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 17,
      deal: "all",
      stage: "price",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 18,
      deal: "all",
      stage: "floor",
      usage: ["apartment", "commercial"],
      commercialUsage: ["apartment", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 19,
      deal: "all",
      stage: "furnished",
      usage: ["apartment", "commercial", "house"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 20,
      deal: "all",
      stage: "size",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 21,
      deal: "all",
      stage: "heating",
      usage: ["apartment", "commercial", "house", "multi"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 22,
      deal: "all",
      stage: "year",
      usage: ["apartment", "commercial", "house", "multi"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 23,
      deal: "all",
      stage: "other",
      usage: ["apartment", "commercial", "house", "multi"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 24,
      deal: "all",
      stage: "facilities",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 25,
      deal: "all",
      stage: "garagePrice",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 26,
      deal: "all",
      stage: "openAreas",
      usage: ["apartment", "commercial", "house"],
      commercialUsage: ["apartment", "hall", "salesroom"],
      areaUsage: "all"
    },
    {
      id: 27,
      deal: "all",
      stage: "openAreasMulti",
      usage: ["multi"],
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 28,
      deal: "all",
      stage: "inspection",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 29,
      deal: "all",
      stage: "notes",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    },
    {
      id: 30,
      deal: "all",
      stage: "summary",
      usage: "all",
      commercialUsage: "all",
      areaUsage: "all"
    }, 
  ];

  const actualIndex = createObjectRoute.findIndex(el => el.stage === stage);
  const actualStage = createObjectRoute[actualIndex];

  // useEffect(() => {
  //   setShowProgress(true);
  //   const timeout = setTimeout(() => {
  //     setShowProgress(false);
  //   }, 4000);

  //   return () => clearTimeout(timeout);
  // }, [stage]);

  const nextStage = (stagePropValue) => {
    // console.log("ACTUALSTAGE:", actualStage);
    // console.log("PROPVALUE:", stagePropValue);

    // Exceptions
    if(actualStage.stage === "type") {
      if (stagePropValue === "commercial") {
        return setStage("presentIndustry");
      } else {
        return setStage("deal");
      }
    }  
    if(actualStage.stage === "facilities") {
      if (objectData.garage) {
        return setStage("garagePrice");
      } else {
        return setStage("openAreas");
      }
    } 

    for (let i = actualIndex + 1; i < createObjectRoute.length; i++) {
      if (createObjectRoute[i].usage === "all" || createObjectRoute[i].usage.includes(objectData?.type)) {
        if(createObjectRoute[i].deal === "all" || createObjectRoute[i].deal.includes(objectData?.deal)) {
          if(createObjectRoute[i].commercialUsage === "all" || (objectData?.type === "commercial" ? createObjectRoute[i].commercialUsage?.includes(objectData?.commercialType) : true)) {
            if(createObjectRoute[i].areaUsage === "all" || createObjectRoute[i].areaUsage === objectData?.openAreas) {
              setStage(createObjectRoute[i].stage);
              return;
            }
          }
        }
      }
    }
  };

  const lastStage = () => {
    // Exceptions
    if(["openAreas", "openAreasMulti"].includes(actualStage.stage)) {
      if (objectData.garage) {
        return setStage("garagePrice");
      } else {
        return setStage("facilities");
      }
    } 

    for (let i = actualIndex - 1; i >= 0; i--) {
      if (createObjectRoute[i].usage === "all" || createObjectRoute[i].usage.includes(objectData?.type)) {
        if(createObjectRoute[i].deal === "all" || createObjectRoute[i].deal?.includes(objectData?.deal)) {
          if(createObjectRoute[i].commercialUsage === "all" || (objectData?.type === "commercial" ? createObjectRoute[i].commercialUsage?.includes(objectData?.commercialType) : true)) {
            if(createObjectRoute[i].areaUsage === "all" || createObjectRoute[i].areaUsage === objectData?.openAreas) {
              setStage(createObjectRoute[i].stage);
              return;
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if(createdObjectID !== undefined) {
      if(objectComissionContractFile !== null) {
        handleFileSubmit(createdObjectID)
        .catch(() => {})
      } else {
        setStage("expose");
        setIsLoading(false);
        setIsCreating(false);
      }
    }
  }, [createdObjectID]);

  const create = async (isActive) => {
    setIsCreating(true);
    
    await objectCreation({object: {...objectData, price: parseFloat(objectData?.price)}, activate: isActive })
    .then((result) => {
      setCreatedObjectID(result.data.docId);
    })
    .catch(() => {})
    
    toast.success(t('CreationDoneInfo'));
  };

  const handleReset = () => {
    setStage("type");
  };

  const handleFileSubmit = async (objectID) => {
    if (!objectComissionContractFile) return;
    if (objectComissionContractFile.size > 1024 * 1024 * 5) {
      alert(t("Upload.DateiZuGroß"));
      return;
    }

    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(objectComissionContractFile.type)
    ) {
      return;
    }

    const storageRef = ref(storage, `objects/${objectID}/comissionContract`);
    const uploadTask = uploadBytesResumable(storageRef, objectComissionContractFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await updateFirestoreDoc("objects", createdObjectID, {comissionContractLink: downloadURL})
          .then(() => {
            setStage("expose");
            setIsLoading(false);
          })
          .catch(() => {});
        });
      }
    );
  };

  return (
    <div className='w-full flex flex-col items-center px-1.5 pb-5 pt-3 md:pt-10'>
      <CreationProgressbar val={((actualIndex) / (createObjectRoute.length - 1)) * 100} visible={showProgress && actualIndex > 0 && actualIndex !== (createObjectRoute.length - 1)}/>
      {stage === "type" && <Type next={(e) => nextStage(e)} />}
      {stage === "presentIndustry" && <PresentIndustry next={() => nextStage()} back={lastStage}/>}
      {stage === "licensing" && <Licensing next={() => nextStage()} back={lastStage}/>}
      {stage === "commercialType" && <CommercialType next={() => nextStage()} back={lastStage}/>}
      {stage === "housetype" && <HouseType next={() => nextStage()} back={lastStage}/>}
      {stage === "deal" && <Deal next={() => nextStage()} back={lastStage}/>}
      {stage === "location" && <ObjectMap next={() => nextStage()} back={lastStage}/>}
      {stage === "address" && <Address next={() => nextStage()} back={lastStage}/>}
      {stage === "style" && <Style next={() => nextStage()} back={lastStage}/>}
      {stage === "condition" && <Condition next={() => nextStage()} back={lastStage}/>}
      {stage === "units" && <Units next={() => nextStage()} back={lastStage}/>}
      {stage === "unitsState" && <UnitsState next={() => nextStage()} back={lastStage}/>}
      {stage === "unitsCondition" && <UnitsCondition next={() => nextStage()} back={lastStage}/>}
      {stage === "state" && <State next={() => nextStage()} back={lastStage}/>}
      {stage === "commercialVacancy" && <CommercialVacancy next={() => nextStage()} back={lastStage}/>}
      {stage === "landProps" && <LandProperties next={() => nextStage()} back={lastStage}/>}
      {stage === "price" && <Price next={() => nextStage()} back={lastStage}/>}
      {stage === "floor" && <Floor next={() => nextStage()} back={lastStage}/>}
      {stage === "furnished" && <Furnished next={() => nextStage()} back={lastStage}/>}
      {stage === "size" && <Size next={() => nextStage()} back={lastStage}/>}
      {stage === "heating" && <Heating next={() => nextStage()} back={lastStage}/>}
      {stage === "year" && <ConstructionYear next={() => nextStage()} back={lastStage}/>}
      {stage === "other" && <OtherInfos next={() => nextStage()} back={lastStage}/>}
      {stage === "facilities" && <Facilities next={() => nextStage()} back={lastStage}/>}
      {stage === "garagePrice" && <GaragePrice next={() => nextStage()} back={lastStage}/>}
      {stage === "openAreas" && <OpenAreas next={() => nextStage()} back={lastStage}/>}
      {stage === "openAreasMulti" && <OpenAreasMulti next={() => nextStage()} back={lastStage}/>}
      {stage === "inspection" && <Inspection next={() => nextStage()} back={lastStage}/>}
      {stage === "notes" && <Notes next={() => nextStage()} back={lastStage}/>}
      {stage === "summary" && <Summary _create={create} _reset={handleReset} back={lastStage}/>}
      {stage === "expose" && <Expose objectID={createdObjectID} next={() => setStage("pics")}/>}
      {stage === "pics" && <Pics objectID={createdObjectID} back={() => setStage("expose")}/>}
    </div>
  ) 
}

export default NewObject
