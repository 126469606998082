import AnimationIcon from '../components/Generics/AnimationIcon';
import { t } from 'i18next';
import { useStateContext } from '../contexts/ContextProvider';
import { updateFirestoreDoc } from '../helper/functions';
import { auth } from '../firebase.config';

const Cookies = () => {

  const { customer } = useStateContext();

  const changeCookieSetting = () => {
    if(auth.currentUser) {
      updateFirestoreDoc("customers", auth.currentUser.uid, {"settings.cookies": !customer?.settings?.cookies})
    }
  };

  return (
    <div className='fixed gap-2 ssm:gap-3 bottom-1 left-1 right-1 flex flex-col items-center p-1 ssm:p-3 shadow-xl bg-white rounded-lg border-2 border-allimmoDark'>
      <div className='flex flex-col ssm:flex-row w-full lg:w-2/3 justify-center items-center gap-2'>
        <AnimationIcon iconSrc={require('../assets/animations/cookies.json')} _iconSize={80} _color={false} />
        <p className='w-full flex flex-row'>{t('Einstellungen.CookieText')}</p>
      </div>
      <div className='flex flex-col gap-1 items-center mt-3 '>
          <p onClick={() => window.open("https://www.allimmo.at/datenschutzerklaerung/", "_blank")} className='text-sm italic text-allimmoDark hover:underline cursor-pointer'>{t('Einstellungen.ZurDatenschutzerklärung')}</p>
          <p onClick={changeCookieSetting} className='text-lg text-allimmoDark hover:underline cursor-pointer'>{t('General.Akzeptieren')}</p>
        </div>
    </div>
  )
}

export default Cookies