import { createContext, useContext, useState } from "react";

const NavigationContext = createContext();

const initialMenuState = {
    admin: false,
    dashboard: false,
    objects: false,
    object: false,
    searches: false,
    settings: false,
    chats: false,
    shop: false,
    account: false,
    contact: false,
    stats: false,
    about: false,
    privacy: false,
    agb: false,
    rating: false,
    updates: false,
    newObject: false,
    newSearch: false,
    affiliate: false,
    impressum: false,
    faq: false,
    support: false,
    team: false,
    calendar: false,
    saved: false,
    onboarding: false,
};
  
export const NavigationProvider = ({ children }) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [menuState, setMenuState] = useState(initialMenuState);

    const handleMenuState = (clicked) => {
      setMenuState({ ...initialMenuState, [clicked]: true });
      setToggleMenu(false);
    };
    
    return (
        <NavigationContext.Provider
            value={{ 
                menuState,
                toggleMenu,
                handleMenuState,
                setToggleMenu,
                setMenuState,
            }}>
                {children}
        </NavigationContext.Provider>
    )
};

export const useNavigationContext = () => useContext(NavigationContext)
