import { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebase.config";
import { collection, onSnapshot, query, where } from "firebase/firestore";

const SearchContactsContext = createContext();

export const SearchContactsProvider = ({ children }) => {
    const [searchContacts, setSearchContacts] = useState([]);

    useEffect(() => {
        const searchesContactsQuery = query(collection(firestore, "contacts"), where("searchUserId", "==", auth.currentUser.uid));
        const unsubSearchContacts = onSnapshot(searchesContactsQuery, (result) => {
            setSearchContacts(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
        });
              
        return () => unsubSearchContacts();
    }, []);

    return (
        <SearchContactsContext.Provider
            value={{ 
                searchContacts,
                setSearchContacts
            }}>
                {children}
        </SearchContactsContext.Provider>
    )
};

export const useSearchContactsContext = () => useContext(SearchContactsContext)
