import { Dialog } from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";
import { Transition } from "../helper/functions";
import CompleteProfile from "./Popups/CompleteProfile";
import { usePopupContext } from "../contexts/PopupProvider";
import BuySearch from "./Popups/BuySearch";
import ReactPlayer from "react-player";
import { useDeletionContext } from "../contexts/DeletionProvider";
import { t } from "i18next";
import DeleteSearch from "./Popups/DeleteSearch";
import DeleteObject from "./Popups/DeleteObject";
import RefundInfo from "./Popups/RefundInfo";
import EditSearchName from "./Popups/EditSearchName";
import Onboarding from "./Popups/Onboarding";
import { useEffect } from "react";

const PopupManager = () => {

  const { customer } = useStateContext();
  const { buySearchOpen, setBuySearchOpen, videoOpen, setVideoOpen, setUpdatesOpen, refundInfoOpen, setRefundInfoOpen,
    editSearchNameOpen, objectVideoOpen, searchVideoOpen, setObjectVideoOpen, setSearchVideoOpen, setChatDeleteConfirmOpen, chatDeleteConfirmOpen, mapVideoOpen, setMapVideoOpen, onboardingOpen } = usePopupContext();
  const { searchDeleteConfirmOpen, objectDeleteConfirmOpen, setSearchDeleteConfirmOpen, setObjectDeleteConfirmOpen } = useDeletionContext();


  const backdropClose = (event, reason) => {
    if (reason && event === "backdropClick") 
        return;
    setVideoOpen(false);
    setObjectVideoOpen(false);
    setSearchVideoOpen(false);
    setSearchDeleteConfirmOpen(false);
    setObjectDeleteConfirmOpen(false);
    setBuySearchOpen(false);
    setRefundInfoOpen(false);
    setUpdatesOpen(false);
    setChatDeleteConfirmOpen(false);
    setMapVideoOpen(false);
  };

  return (
    <div>
        {/* complete profile */}
        <Dialog open={customer?.name === "" || customer?.agbAccepted === false} fullWidth={true} fullScreen={true} TransitionComponent={Transition}>
            <CompleteProfile />
        </Dialog>

        <Dialog open={(customer?.name !== "" && customer?.agbAccepted && !customer?.onboarding) || onboardingOpen} fullWidth={true} fullScreen={true} TransitionComponent={Transition}>
          <Onboarding />
        </Dialog>

        <Dialog  onClose={(e, r) => backdropClose(e,r)} fullWidth open={buySearchOpen} TransitionComponent={Transition}>
          <BuySearch />
        </Dialog>

        <Dialog  onClose={(e, r) => backdropClose(e,r)} fullWidth open={refundInfoOpen} TransitionComponent={Transition}>
          <RefundInfo />
        </Dialog>

        <Dialog  onClose={(e, r) => backdropClose(e,r)} fullWidth open={editSearchNameOpen} TransitionComponent={Transition}>
          <EditSearchName />
        </Dialog>

        <Dialog onClose={(e, r) => backdropClose(e,r)} fullWidth maxWidth="sm" open={videoOpen} TransitionComponent={Transition}>
          <div className="flex flex-row justify-center items-center">
            <ReactPlayer playing url="https://youtu.be/1eGh1K3gwuA?si=tdOdH12o6a88Py2B" width={640} height={338}/>
          </div>
        </Dialog>

        <Dialog onClose={(e, r) => backdropClose(e,r)} fullWidth maxWidth="sm" open={objectVideoOpen} TransitionComponent={Transition}>
          <div className="flex flex-row justify-center items-center">
            <ReactPlayer playing url="https://www.youtube.com/watch?v=chO-eG4ePAg" width={640} height={338}/>
          </div>
        </Dialog>
        
        <Dialog onClose={(e, r) => backdropClose(e,r)} fullWidth maxWidth="sm" open={searchVideoOpen} TransitionComponent={Transition}>
          <div className="flex flex-row justify-center items-center">
            <ReactPlayer playing url="https://www.youtube.com/watch?v=OX3aCSOGMrg" width={640} height={338}/>
          </div>
        </Dialog>

        <Dialog onClose={(e, r) => backdropClose(e,r)} fullWidth maxWidth="sm" open={mapVideoOpen} TransitionComponent={Transition}>
          <div className="flex flex-row justify-center items-center">
            <ReactPlayer playing url="https://youtu.be/dw2Po1OqqpM?si=1u4KWM73oXtEpK7c" width={640} height={338}/>
          </div>
        </Dialog>

        <Dialog onClose={(e, r) => backdropClose(e,r)} open={searchDeleteConfirmOpen} fullWidth={true} TransitionComponent={Transition}>
          <DeleteSearch />
        </Dialog>

        <Dialog onClose={(e, r) => backdropClose(e,r)} open={objectDeleteConfirmOpen} fullWidth={true} TransitionComponent={Transition}>
          <DeleteObject />
        </Dialog>

        <Dialog onClose={(e, r) => backdropClose(e,r)} open={chatDeleteConfirmOpen} TransitionComponent={Transition}>
            <div className='flex flex-col justify-center items-center p-5 select-none w-full'>
                <p className="text-center w-full">{t("DeleteChat.Text")}</p>
        
                <div className='flex flex-row justify-center mt-8 w-full gap-2'>
                    <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={() => setChatDeleteConfirmOpen(false)}>{t('General.Abbrechen')}</button>
                    <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80'>{t('General.Löschen')}</button>
                </div>
            </div>
        </Dialog>
    </div>
  )
};

export default PopupManager
