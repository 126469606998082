import { sendEmailVerification } from "firebase/auth";
import { t } from "i18next";
import { useState } from "react";
import CopyrightInfo from "../components/CopyrightInfo";
import Button from "../components/Generics/Button";
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { useStateContext } from "../contexts/ContextProvider";
import { useCreationContext } from "../contexts/CreationProvider";
import { usePopupContext } from "../contexts/PopupProvider";
import { auth } from "../firebase.config";
import TypewriterSlogans from "../components/Typewriter";
import EventLogos from "../components/EventLogos.jsx";
import ProspectStats from "../components/Dashboard/ProspectStats.jsx";
import ObjectStats from "../components/Dashboard/ObjectStats.jsx";
import SearchStats from "../components/Dashboard/SearchStats.jsx";
import OfferStats from "../components/Dashboard/OfferStats.jsx";
import NotificationBoard from "../components/Dashboard/NotificationBoard.jsx";
import CreditsBoard from "../components/Dashboard/CreditsBoard.jsx";
import { CreditTransactionsProvider } from "../contexts/CreditTransactionsProvider.js";
import { ObjectContactsProvider } from "../contexts/ObjectContactsProvider.js";
import { SearchContactsProvider } from "../contexts/SearchContactsProvider.js";
import { MatchesProvider } from "../contexts/MatchesProvider.js";
import { useNavigationContext } from "../contexts/NavigationProvider.js";

const Dashboard = () => {
  const { resetSearchData, resetObjectData } = useCreationContext();
  const { setLoginOpen, searchProfilesLeft } = useStateContext();
  const { handleMenuState } = useNavigationContext();
  const { setBuySearchOpen } = usePopupContext();

  const [resendLoading, setResendLoading] = useState(false);

  const handleSearchClick = () => {
    if (auth.currentUser) {
      if (searchProfilesLeft === 0) {
        setBuySearchOpen(true);
      } else {
        resetSearchData();
        handleMenuState("newSearch");
      }
    } else {
      setLoginOpen(true);
    }
  };

  const handleObjectClick = () => {
    if (auth.currentUser) {
      resetObjectData();
      handleMenuState("newObject");
    } else {
      setLoginOpen(true);
    }
  };

  const resendVerificationEmail = async () => {
    setResendLoading(true);
    try {
      await sendEmailVerification(auth.currentUser).then(() => {
        setResendLoading(false);
      });
    } catch (err) {
      setResendLoading(false);
    }
  };

  const DashboardItem = ({content, clickFunction, half = false, cursor = false}) => {
    return (
      <div onClick={clickFunction} className={`${cursor ? "cursor-pointer hover:border-allimmoDark/80" : ""} relative flex flex-col justify-center items-center h-500 sm:h-[250px] ${half ? "w-1/2" : "w-full"} p-3 900:p-5 rounded-xl border border-gray-300 shadow-[0px_0px_8px_-4px_#8a8a8a] bg-white`}>
        {content}
      </div>
    )
  };

  return (
    <div className="flex flex-col w-full px-1 py-1 lg:py-2 lg:px-2 gap-2 lg:gap-4">
      {/* <div className="flex flex-col 1100:flex-row w-full px-2 py-5 lg:px-5 gap-2 lg:gap-4">
        {(auth.currentUser && auth.currentUser?.emailVerified) &&
        <div className="flex flex-col 800:flex-row 1100:flex-col items-center gap-2 lg:gap-4 w-full">
          <CreditTransactionsProvider>
            <MatchesProvider>
              <SearchContactsProvider>
                <DashboardItem content={<NotificationBoard />} />
              </SearchContactsProvider>
            </MatchesProvider>
            <DashboardItem content={<CreditsBoard />} />
          </CreditTransactionsProvider>
        </div>}
        {(auth.currentUser && auth.currentUser?.emailVerified) &&
        <div className="flex flex-col 800:flex-row 1100:flex-col items-center gap-2 lg:gap-4 w-full">
            <DashboardItem cursor clickFunction={() => handleMenuState("objects")} content={<ObjectStats/>} />
          <ObjectContactsProvider>
            <MatchesProvider>
              <DashboardItem cursor clickFunction={() => handleMenuState("objects")} content={<ProspectStats/>}/>
            </MatchesProvider>
          </ObjectContactsProvider>
        </div>}
        {(auth.currentUser && auth.currentUser?.emailVerified) &&
        <div className="flex flex-col 800:flex-row 1100:flex-col items-center gap-2 lg:gap-4 w-full">
            <DashboardItem cursor clickFunction={() => handleMenuState("searches")} content={<SearchStats/>} />
          <SearchContactsProvider>
            <DashboardItem cursor clickFunction={() => handleMenuState("searches")} content={<OfferStats/>}/>
          </SearchContactsProvider>
        </div>}
      </div> */}
      {(auth.currentUser && !auth.currentUser?.emailVerified) &&
         <div className="flex flex-col items-center mb-10 w-full">
           <p className="text-lg text-center text-allimmoDark">
             {t("Alerts.EmailBestätigen")}
           </p>
           <p className="italic text-lg text-center text-allimmoDark/50 mb-6">
             {t("Alerts.CheckSpam")}
           </p>
           <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 justify-center">
             <Button
               clickFunction={resendVerificationEmail}
               loading={resendLoading}
               text={t("General.ErneutSenden")}
             />
             <Button
               clickFunction={() => window.location.reload()}
               loading={!auth.currentUser}
               text={t("Dashboard.EmailBestätigt")}
             />
           </div>
      </div>}
     {(!auth.currentUser || (auth.currentUser && auth.currentUser?.emailVerified)) &&
     <div className="flex flex-col justify-center items-center w-full">
        <div className={`flex flex-col justify-center items-center gap-2 w-full px-3 ${auth.currentUser ? "mt-24" : "mt-0"}`}>
           {!auth.currentUser && <TypewriterSlogans/>}
           <div className={`flex flex-row justify-center items-center gap-2 md:gap-8 w-full px-3 mt-8`}>
             {/* Suchprofil erstellen */}
             <div onClick={handleSearchClick} className="flex flex-col cursor-pointer hover:shadow-none active:bg-sky-100 justify-center items-center border-2 shadow-2xl border-allimmoDark aspect-square h-150 sm:h-180 md:h-200 p-5 rounded-xl bg-white">
               <img width={40} src={require("../assets/houseDashboard.gif")} alt="" />
               <p className="text-allimmoDark text-sm sm:text-xl w-full text-center mb-2 sm:mb-4">
                 {t("Dashboard.SearchingObjects")}
               </p>

               {!auth.currentUser && (
                 <>
                   <p className="text-allimmoDark/70 font-semibold">
                     {t("Dashboard.Kostenlos")}
                   </p>
                   <div className="flex flex-row justify-center items-center space-x-1">
                     <p className="text-allimmoDark/70 italic text-xs">
                       {t("Dashboard.CreateSearch")}
                     </p>
                   </div>
                   {searchProfilesLeft === 0 && (
                     <p className="text-allimmoDark/70 italic text-xs">
                       {t("Dashboard.ProSuchprofil")}
                     </p>
                   )}
                 </>
               )}

               {auth.currentUser && (
                 <>
                   {searchProfilesLeft > 0 && (
                     <p className="text-allimmoDark/70">
                       {t("Dashboard.Verfügbar")}
                     </p>
                   )}
                   {searchProfilesLeft === 0 && (
                     <p className="text-allimmoDark/70">
                       {t("Dashboard.Credits")}
                     </p>
                   )}
                   {searchProfilesLeft > 0 && (
                     <div className="flex flex-row justify-center items-center space-x-1">
                       <p className="text-allimmoDark/70 italic text-xs">
                         {t("Dashboard.Noch")}
                       </p>
                       <p className="text-allimmoDark/70 italic text-xs">
                         {searchProfilesLeft}
                       </p>
                       <p className="text-allimmoDark/70 italic text-xs">
                         {searchProfilesLeft > 1
                           ? t("Dashboard.Suchprofile")
                           : t("Dashboard.Suchprofil")}
                       </p>
                     </div>
                   )}
                   {searchProfilesLeft === 0 && (
                     <p className="text-allimmoDark/70 italic text-xs">
                       {t("Dashboard.ProSuchprofil")}
                     </p>
                   )}
                 </>
               )}
             </div>

            {/* Objekt erstellen */}
            <div onClick={handleObjectClick} className="flex flex-col cursor-pointer hover:shadow-none active:bg-sky-100 justify-center items-center border-2 shadow-2xl border-allimmoDark aspect-square h-150 sm:h-180 md:h-200 p-5 rounded-xl bg-white">
              <img width={40} src={require("../assets/zoomDashboard.gif")} alt="" />
              <p className="text-allimmoDark text-sm sm:text-xl w-full text-center mb-2 sm:mb-4">
                {t("Dashboard.SearchingProspects")}
              </p>
              {!auth.currentUser && (
                <p className="text-allimmoDark/70 font-semibold">{t("Dashboard.Kostenlos")}</p>
              )}
              {auth.currentUser && (
                <p className="text-allimmoDark/70">{t("Dashboard.Credit")}</p>
              )}
              <p className="text-allimmoDark/70 italic text-xs">
                {auth.currentUser ? t("Dashboard.ProInteressent") : t("Dashboard.CreateObject")}
              </p>
            </div>
          </div>
        </div>

        {!auth.currentUser && <AboutAnimation />}
        {!auth.currentUser && <EventLogos />}
        {/* <p>{searchParams.get("code")}</p> */}
        
      </div>}
      <CopyrightInfo />
    </div>  
  );
};

const AboutAnimation = () => {
  const { setOnboardingOpen } = usePopupContext();

  return (
    <div className="flex flex-col items-center justify-center cursor-pointer mt-6 w-full"
      onClick={() => setOnboardingOpen(true)}
    >
      <p className="w-full text-xl text-center text-allimmoDark italic px-2.5 py-0.5 rounded hover:text-allimmoDark/70">
        {t("Dashboard.AboutButtonText")}
      </p>
      <div className="w -mt-3.5">
        <AnimationIcon
          _color={false}
          _iconSize={40}
          iconSrc={require("../assets/animations/tap.json")}
          labelText={t("CreateObject.PicsLabel")}
        />
      </div>
    </div>
  );
};

export default Dashboard;
