import { useStateContext } from '../contexts/ContextProvider';
import AdminMenu from '../components/Admin/AdminMenu';
import { useAdminContext } from '../components/Admin/Contexts/AdminProvider';
import DeleteUserSearches from '../components/Admin/Components/DeleteUserSearches';
import Home from '../components/Admin/Components/Home';
import SingleUserSummary from '../components/Admin/Components/SingleUserSummary';
import DeleteSingleUser from '../components/Admin/Components/DeleteSingleUser';
import ActivateAutoContact from '../components/Admin/Components/ActivateAutoContact';
import GlobalMatching from '../components/Admin/Components/GlobalMatching';

const Admin = () => {
    const { adminEmail } = useStateContext();
    const { selectedMenu, selectedSub } = useAdminContext();

  return (
    <div className='flex flex-col w-full mx-10 mt-5'>
        <p className='text-xs italic w-full text-end mb-0.5 font-thin text-allimmoDark opacity-20'>admin-dashboard v0.0.2</p>
        <AdminMenu />
        {selectedMenu === "main" && <Home />}
        {selectedSub === "deleteSearches" && <DeleteUserSearches />}
        {selectedSub === "singleUserSummary" && <SingleUserSummary />}
        {selectedSub === "deleteSingleUser" && <DeleteSingleUser />}
        {selectedSub === "activateAutoContact" && <ActivateAutoContact />}
        {selectedSub === "globalMatching" && <GlobalMatching />}
    </div>
  )
}

export default Admin
