import { CircularProgress } from "@mui/material";
import { browserSessionPersistence, setPersistence } from "firebase/auth";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from 'react-router-dom';
import { useStateContext } from "./contexts/ContextProvider";
import { useSearchesContext } from "./contexts/SearchesProvider";
import { auth, firestore } from './firebase.config';
import MemberArea from "./pages/MemberArea";
import ObjectListing from "./pages/ObjectListing";
import i18n from "./i18n";

export default function App() {
  const { setCustomer, setIsLoading, setUpdates, setCustomerChats, setLoginOpen } = useStateContext();
  const { setCustomerSearches } = useSearchesContext();
  const [user, loading] = useAuthState(auth);
  const { ready } = useTranslation(undefined, {useSuspense: false});

  useEffect(() => {
    if(user) {
      setLoginOpen(false);
      setPersistence(auth, browserSessionPersistence);
      
      // realtime-snapshot on the platform update infos collection
      const updatesQuery = query(collection(firestore, "updates"), where("active", "==", true));
      const unsubUpdatesData = onSnapshot(updatesQuery, (result) => {
        setUpdates(result.docs.map((doc) => ({...doc.data(), id: doc.id })))
      });

      // realtime-snapshot on the userRecord of authenticated user
      const customerRef = doc(firestore, "customers", auth.currentUser.uid);
      const unsubCustomerData = onSnapshot(customerRef, (result) => {
        setCustomer(result.data());
        // change language to the setting entry, received from the customer DB record
        i18n.changeLanguage(result.data()?.language ?? "de");
      });

      const searchesQuery = query(collection(firestore, "searches"), where("customer", "==", auth.currentUser.uid), where("deleted", "==", false));
      const unsubSearches = onSnapshot(searchesQuery, (result) => {
        setCustomerSearches(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
      });

      const chatsQuery = query(collection(firestore, "chats"), where("members", "array-contains", auth.currentUser.uid));
      const unsubChats = onSnapshot(chatsQuery, (result) => {
        setCustomerChats(result.docs.filter(chat => chat?.data()?.deleted ? !chat?.data()?.deleted?.includes(auth.currentUser.uid) : true).map((doc) => ({...doc.data(), id: doc.id })));
      });

      return () => { 
        unsubCustomerData();
        unsubUpdatesData();
        unsubSearches();
        unsubChats();
      }
    }
    // eslint-disable-next-line 
  }, [user]);

  useEffect(() => {
    if(loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line 
  }, [loading]);

  return (
    <div className='flex flex-col justify-center items-center h-full w-full select-none'>
        {!ready ? <CircularProgress size={60}/>:
        <Routes>
          <Route path='matchaccept/:user' element={<MemberArea sub="matchaccept"/>} />
          <Route path='faq' element={<MemberArea sub="faq"/>} />
          <Route path='support' element={<MemberArea sub="support"/>} />
          <Route path='about' element={<MemberArea sub="about"/>} />
          <Route path='team' element={<MemberArea sub="team"/>} />
          <Route path='tips' element={<MemberArea sub="tips"/>} />
          <Route path='objects'>
            <Route path=':id' element={<ObjectListing />} />
          </Route>
          <Route index path='/member/*' element={<MemberArea />} />
          <Route path="*" element={<Navigate to="/"/>}/>
          <Route path='/' element={<MemberArea />} />
        </Routes>}
    </div>
  );
};
