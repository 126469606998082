import { Checkbox, Collapse, FormControlLabel, Slider } from "@mui/material";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { t } from "i18next";
import { useEffect, useState } from "react";
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { useStateContext } from "../contexts/ContextProvider.js";
import { auth, storage } from "../firebase.config.js";
import { autoContactExisting, updateFirestoreDoc } from "../helper/functions.js";
import { Delete, Dot } from "../helper/icons.js";
import Button from "../components/Generics/Button.jsx";
import Popup from "../components/Generics/Popup.jsx";
import ActivateAutoContactSetting from "../components/Popups/ActivateAutoContactSetting.jsx";
import Input from "../components/Generics/Input.jsx";
import validator from "validator";
import { arrayUnion, Timestamp } from "firebase/firestore";

const Settings = () => {
  const { customer } = useStateContext();

  const [autoContactCreditsLimit, setAutoContactCreditsLimit] = useState(customer?.settings?.autoContactLimit ?? 0);
  const [limitUpdating, setLimitUpdating] = useState(false);
  const [autoContactConfirm, setAutoContactConfirm] = useState(false);
  const [newAcceptedContactEmail, setNewAcceptedContactEmail] = useState(customer?.settings?.acceptedContactEmail ?? auth.currentUser.email);

  const validateAcceptedContactEmail = !validator.isEmail(newAcceptedContactEmail) || [customer?.settings?.acceptedContactEmail, auth.currentUser.email].includes(newAcceptedContactEmail);
  const allSearchDepthOff = customer?.settings?.autoContactSearchDepth?.genau === false && customer?.settings?.autoContactSearchDepth?.normal === false && customer?.settings?.autoContactSearchDepth?.ungenau === false;
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateSetting = async (type, historyAction = "") => {
    const newData = {
      settings: {...customer?.settings, [type]: !customer?.settings[type] },
    };

    if(historyAction !== "") {
      newData.history = arrayUnion({
        action: historyAction,
        created: Timestamp.fromDate(new Date()),
        executor: "user",
      });
    }

    await updateFirestoreDoc("customers", auth.currentUser.uid, newData);
  };

  const handleAutoContact = async () => {
    if(customer?.settings?.autoContact) {
      updateSetting("autoContact", "auto-contact-deactivated");
    } else {
      setAutoContactConfirm(true);
    }
  };

  const handleAutoContactActivation = async () => {
    try {
      setAutoContactConfirm(false);
      await autoContactExisting({
        genau: customer?.settings?.autoContactSearchDepth?.genau ?? true, 
        normal: customer?.settings?.autoContactSearchDepth?.normal ?? true, 
        ungenau: customer?.settings?.autoContactSearchDepth?.ungenau ?? true,
        creditsLimit: autoContactCreditsLimit,
      });
      updateSetting("autoContact", "auto-contact-activated");
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleAutoContactDepth = async (depthToChange) => {
    const depth = customer?.settings?.autoContactSearchDepth ?? false;
    const newData = {
      settings: { 
        ...customer?.settings,
        autoContactSearchDepth: {...depth, [depthToChange]: !depth[depthToChange]}
      },
    };
    await updateFirestoreDoc("customers", auth.currentUser.uid, newData);
  };

  const updateAutoContactLimit = async () => {
    setLimitUpdating(true);
    await updateFirestoreDoc("customers", auth.currentUser.uid, { settings: {...customer?.settings, autoContactLimit: autoContactCreditsLimit } })
    .then(() => {
      setLimitUpdating(false);
    });
  };

  const deleteFile = async (type) => {
    await updateFirestoreDoc("customers", auth.currentUser.uid, { [type]: "" });
    const fileRef = ref(storage, `customers/${auth.currentUser.uid}/${type === "comissionContractLink" ? "comissionContract" : "privacyContract"}`);
    deleteObject(fileRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // An error occurred!
    });
  };

  const updateAcceptedContactEmail = async () => {
    if ([customer?.settings?.acceptedContactEmail, auth.currentUser.email].includes(newAcceptedContactEmail)) return;

    if(validator.isEmail(newAcceptedContactEmail)) {
      await updateFirestoreDoc("customers", auth.currentUser.uid, {settings: {...customer.settings, acceptedContactEmail: newAcceptedContactEmail}});
    } else {
      alert("Bitte geben Sie eine gültige Email Adresse ein");
    }
  };

  return (
    <div className="w-full flex flex-col items-center mt-5 md:mt-10 mb-20">
      <div className="flex flex-col w-full md:w-2/3 lg:w-1/2 px-1 sm:px-2 py-1">
        <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.Allgemein")}
        </p>
        <div className="p-3 ssm:p-5 border border-allimmoDark/80 flex flex-col rounded-lg w-full mb-3 bg-allimmoDark/5">
          <FormControlLabel 
          control={<Checkbox value={customer?.settings?.emails} checked={customer?.settings?.emails} onChange={() => updateSetting("emails")} />}
          label={
            <p className="text-sm sm:text-base">
                {t("Einstellungen.KeineBenachrichtigungen")}
            </p>
          } />

          <FormControlLabel
          control={<Checkbox value={customer?.settings?.marketing} checked={customer?.settings?.marketing} onChange={() => updateSetting("marketing")} />}
          label={
            <p className="text-sm sm:text-base">
              {t("Einstellungen.KeinMarketing")}
            </p>
          } />

          <div className="flex flex-col border-y border-dashed border-allimmoDark/60 py-0.5">
            <FormControlLabel
              control={
                <Checkbox
                  value={customer?.settings?.contactAcceptedEmails}
                  checked={customer?.settings?.contactAcceptedEmails}
                  onChange={() => updateSetting("contactAcceptedEmails")}
                />
              }
              label={
                <p className="text-sm sm:text-base">
                  {t("Einstellungen.KontaktAkzeptiertEmail")}
                </p>
              }
            />
            <Collapse in={customer?.settings?.contactAcceptedEmails}>
              <div className="flex flex-col pl-5">
                
                <FormControlLabel
                  control={
                    <Checkbox
                    size="small"
                    value={customer?.settings?.contactPersonsEmailAddress}
                    checked={customer?.settings?.contactPersonsEmailAddress}
                    onChange={() => updateSetting("contactPersonsEmailAddress")}
                    />
                  }
                  label={
                    <p className="text-xs sm:text-base">
                      {t("Einstellungen.KontaktPersonEmailVerwenden")}
                    </p>
                  }
                />
                
                <Collapse in={customer?.settings?.contactPersonsEmailAddress === false} >
                  <div className="flex flex-col gap-1 my-2">
                    <Input changeInputValue={(e) => setNewAcceptedContactEmail(e)} showValidation={false} inputValue={newAcceptedContactEmail} inputLabel={t("Einstellungen.ContactAcceptedEmailLabel") + ":"}/>
                    <Button
                      clickFunction={updateAcceptedContactEmail} 
                      isDisabled={validateAcceptedContactEmail} 
                      text={t('Einstellungen.EmailSpeichern')}
                      width={220}
                    />
                  </div>
                </Collapse>
              </div>
            </Collapse>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                value={customer?.settings?.sms}
                checked={customer?.settings?.sms}
                onChange={() => updateSetting("sms")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.SMSBenachrichtigungen")}
              </p>
            }
          />

          {/* {customer?.devices && customer?.devices.some(d => d?.active) &&
          <FormControlLabel
            control={
              <Checkbox
                value={customer?.settings?.push}
                checked={customer?.settings?.push}
                onChange={() => updateSetting("push")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.PushBenachrichtigungen")}
              </p>
            }
          />} */}

          <FormControlLabel
            control={
              <Checkbox
                value={customer.settings?.cookies}
                checked={customer.settings?.cookies}
                onChange={() => updateSetting("cookies")}
              />
            }
            label={
              <p className="text-sm sm:text-base">
                {t("Einstellungen.CookiesErlauben")}
              </p>
            }
          />
          
        </div>

        <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.Objects")}
        </p>
        <div className="p-3 ssm:p-5 border border-allimmoDark/80 flex flex-col rounded-lg w-full mb-3 bg-allimmoDark/5">
          <p>{t("Einstellungen.AutoKontaktHeader")}</p>
          <p className="text-xs italic">{t("Einstellungen.AutoKontaktInfo")}</p>
          <p className="mt-5">{t("Einstellungen.AutoContactCreditsLimit")}</p>
          <p className={`italic text-xs`}>{t("Einstellungen.AutoContactCreditsLimitInfo")}</p>
          <div className="flex flex-row items-center gap-5">
            <Slider max={customer?.credits} value={autoContactCreditsLimit} step={1} onChange={(e) => setAutoContactCreditsLimit(e.target.value)}/>
            <p className={`font-bold w-28 text-end`}>{autoContactCreditsLimit}</p>
          </div>
          {/* <Button 
          width={200} 
          loading={limitUpdating}
          clickFunction={updateAutoContactLimit} 
          text={t("Einstellungen.CreditsÜbernehmen")}
          isDisabled={autoContactCreditsLimit === customer?.credits} /> */}
          
          <p className={`mt-5`}>{t("Einstellungen.AutoKontaktDepthHeader")}</p>
          <p className={`italic text-xs`}>{t("Einstellungen.AutoContactSearchDepthInfo")}</p>
          <div className={`flex flex-row gap-0.5 ssm:gap-5 mb-3`}>

            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  disableTouchRipple
                  disabled={customer?.settings?.autoContact}
                  value={customer?.settings?.autoContactSearchDepth?.genau ?? false}
                  checked={customer?.settings?.autoContactSearchDepth?.genau ?? false}
                  onChange={() => handleAutoContactDepth("genau")}
                />
              }
              label={
                <p className="text-sm sm:text-base">
                  {t("DepthStufen.genau")}
                </p>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  disableTouchRipple
                  disabled={customer?.settings?.autoContact}
                  value={customer?.settings?.autoContactSearchDepth?.normal ?? false}
                  checked={customer?.settings?.autoContactSearchDepth?.normal ?? false}
                  onChange={() => handleAutoContactDepth("normal")}
                />
              }
              label={
                <p className="text-sm sm:text-base">
                  {t("DepthStufen.normal")}
                </p>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  disableTouchRipple
                  disabled={customer?.settings?.autoContact}
                  value={customer?.settings?.autoContactSearchDepth?.ungenau ?? false}
                  checked={customer?.settings?.autoContactSearchDepth?.ungenau ?? false}
                  onChange={() => handleAutoContactDepth("ungenau")}
                />
              }
              label={
                <p className="text-sm sm:text-base">
                  {t("DepthStufen.ungenau")}
                </p>
              }
            />
          </div>

          {!customer?.settings?.autoContact && <Button isDisabled={allSearchDepthOff || customer?.credits === autoContactCreditsLimit} clickFunction={handleAutoContact} text={t('Einstellungen.AutoKontaktAktivierenButtonText')}/>}
          {customer?.settings?.autoContact && <Button clickFunction={handleAutoContact} text={t('Einstellungen.AutoKontaktDeAktivierenButtonText')}/>}
          
        </div>

        <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.FürMakler")}
        </p>
        <div className="p-3 border border-allimmoDark/80 flex flex-col mb-3 rounded-lg w-full gap-2 bg-allimmoDark/5">
          <div className="flex flex-col">
            <div className="flex flex-row items-center gap-1">
            <Dot size={15} className="text-allimmoDark"/>
              
              {customer?.privacyLink !== "" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline cursor-pointer text-allimmoDark text-sm ssm:text-base grow"
                  href={customer?.privacyLink}
                >
                  {t("Einstellungen.DatenschutzAnsehen")}
                </a>
              ) : (
                <p className="text-xs ssm:text-sm">
                  {t("Einstellungen.KeinDatenschutz")}
                </p>
              )}

              {customer?.privacyLink !== "" && (
                <Delete
                  onClick={() => deleteFile("privacyLink")}
                  size={15}
                  className="text-red-500 cursor-pointer hover:text-red-700 active:text-red-500"
                />
              )}
            </div>

            <div className="flex flex-row gap-1 items-center">
              <Dot size={15} className="text-allimmoDark"/>
              
              {customer?.comissionContractLink !== "" ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline cursor-pointer text-allimmoDark text-sm ssm:text-base grow"
                  href={customer?.comissionContractLink}
                >
                  {t("Einstellungen.VereinbarungAnsehen")}
                </a>
              ) : (
                <p className="text-xs ssm:text-sm">
                  {t("Einstellungen.KeineVereinbarung")}
                </p>
              )}
              {customer?.comissionContractLink !== "" && (
                <Delete
                  onClick={() => deleteFile("comissionContractLink")}
                  size={15}
                  className="text-red-500 cursor-pointer hover:text-red-700 active:text-red-500"
                />
              )}
            </div>
          </div>
          {/* <PrivacyUpload /> */}
          {/* <ContractUpload
            header={t("Einstellungen.VereinbarungUpload")}
            uploadType="default"
          /> */}
          <FileUploader title={t('Einstellungen.DatenschutzUpload')} contractType="privacyContract" selectLabel={t("Einstellungen.DateiAuswählen")} linkname="privacyLink" acceptedFileTypes=".pdf, .doc, .docx"/>
          <FileUploader title={t('Einstellungen.VereinbarungUpload')} contractType="comissionContract" selectLabel={t("Einstellungen.DateiAuswählen")} linkname="comissionContractLink" acceptedFileTypes=".pdf, .doc, .docx"/>
        </div>

        {/* <p className="w-full sm:text-xl text-allimmoDark sm:mb-1">
          {t("Einstellungen.Affiliate")}
        </p>
        <div className="p-5 border border-allimmoDark/80 flex flex-col rounded-lg w-full bg-allimmoDark/10">
          <FormControlLabel 
          control={
            <Checkbox
              value={customer?.settings?.affiliate}
              checked={customer?.settings?.affiliate}
              onChange={() => updateSetting("affiliate")}
            />}
          label={t('Einstellungen.AffiliateLabel')}
          />
          {customer?.settings?.affiliate && <p className="italic text-sm text-allimmoDark">{t('Einstellungen.AffiliateInfo')}</p>}
        </div> */}
      </div>
      <Popup full={false} showLogo={false} header="" openState={autoContactConfirm} 
        content={<ActivateAutoContactSetting confirm={handleAutoContactActivation} close={() => setAutoContactConfirm(false)}/>} />
    </div>
  );
};

const FileUploader = ({selectLabel, acceptedFileTypes, contractType, title, linkname, _type = "file"}) => {

  const { screenSize } = useStateContext();

  const [progressPercent, setProgressPercent] = useState(0);
  const [exposeError, setExposeError] = useState(false);
  const [exposeLink, setExposeLink] = useState("");
  const [file, setFile] = useState(null);
  const [finished, setFinished] = useState(false);

  const handleFileSubmit = () => {
    if (!file) return;
    if (file.size > 1024 * 1024 * 5) {
      alert(t("Upload.DateiZuGroß"));
      return;
    }

    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      setExposeError(true);
      return;
    }
    setFinished(false);
    setExposeError(false);

    const storageRef = ref(storage, `customers/${auth.currentUser.uid}/${contractType}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setExposeLink(downloadURL);

          // expose Link to DB
          await updateFirestoreDoc("customers", auth.currentUser.uid, {
            [linkname]: downloadURL,
          });

          setTimeout(() => {
            setFile(null);
            setProgressPercent(0);
            setFinished(true);
          }, "500");
        });
      }
    );
  };

  return (
    <div className="flex-flex-col justify-center items-center w-full">
      <p className="text-xs ssm:text-sm md:text-base -mb-1 mt-1">{title}</p>
      <Collapse className="w-full flex flex-col justify-center items-center" in={file !== null}>
          <div className=" w-full bg-white py-2 pl-2 pr-1 rounded border border-orange-500 border-dashed flex flex-row justify-center items-center gap-1.5">
            <AnimationIcon animate _color={false} _iconSize={40} iconSrc={require(`../assets/animations/objectCreation/doc.json`)}/>
            {file !== null && (
              <div className="flex flex-col justify-center grow">
                <p className="text-allimmoDark grow"><a target='_blank' href={URL.createObjectURL(file)}>
                  {file?.name.substring(
                    0,
                    screenSize.width < 390
                      ? 20
                      : screenSize.width < 390
                      ? 25
                      : 30
                  ) + "..."}</a>
                </p>
                <p className="text-orange-500 italic -mt-1 text-sm">
                  {(file?.size / 1024 ** 2).toFixed(2)} MB
                </p>
              </div>
            )}
            <p
              className="text-2xl text-red-400 cursor-pointer hover:text-red-300 active:text-red-400 w-6 text-center"
              onClick={() => setFile(null)}
            >
              x
            </p>
          </div>
      </Collapse>
      <div className="flex h-12 flex-row items-center justify-center w-full gap-1 mt-1">
        <input
          id="upload"
          hidden
          onChange={(e) => setFile(e.target?.files[0])}
          type={_type}
          accept={acceptedFileTypes}
        />
        <label
          className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100"
          htmlFor="upload">
            <p className="text-center w-full text-allimmoDark text-sm ssm:text-lg">{selectLabel}</p>
        </label>
        {file !== null && progressPercent === 0 && (
            <button onClick={handleFileSubmit} className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100">
              <p className="text-center w-full text-allimmoDark text-sm ssm:text-lg">
                Datei hochladen
              </p>
            </button>
          )}
      </div>
    </div>
  )
}

export default Settings;
