import { t } from 'i18next';
import { Affiliate, ContactMatch, ObjectList } from '../../helper/icons';
import { FaEquals, FaThumbsUp } from 'react-icons/fa';
import { TbMoneybag } from 'react-icons/tb';

const Page6 = () => {
  return (
    <div className='flex flex-col items-center justify-center animate-fade gap-8 rounded-xl border border-gray-400 p-6 bg-white shadow-lg w-full'>
      <p className='text-center font-bold'>{t('Onboarding.Page6Header')}</p>
      
      <div className='flex flex-row items-center justify-center gap-4'>
        <div className='animate-fade animate-delay-300 flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <Affiliate size={70} className='text-orange-300 text-6xl'/>
        </div>

        <FaEquals size={50} className='text-orange-300 text-6xl hidden mmd:block animate-fade animate-delay-[800ms]'/>

        <div className='animate-fade animate-delay-[1000ms] flex flex-row items-center justify-center p-4 border border-orange-300 rounded-xl shadow-md bg-orange-50'>
          <TbMoneybag size={70} className='text-orange-300 animate-wiggle-more animate-infinite text-6xl'/>
        </div>
        
      </div>

      <p className='text-center italic text-sm'>{t('Onboarding.Page6Text')}</p>
    </div>
  )
}

export default Page6