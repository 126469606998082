import { createContext, useContext, useEffect, useState } from "react";
import i18n from "../i18n";

const StateContext = createContext();

const getCurrentDimension = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const countries = ["AT", "DE"];

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [customer, setCustomer] = useState({ credits: 0 });
  const [affiliate, setAffiliate] = useState({});
  const [maintenanceInfo, setMaintenanceInfo] = useState({});
  const [selectedObject, setSelectedObject] = useState();
  const [customerObjects, setCustomerObjects] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [matches, setMatches] = useState([]);
  const [customerSearches, setCustomerSearches] = useState([]);
  const [searchesContacts, setSearchesContacts] = useState([]);
  const [customerChats, setCustomerChats] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoData, setInfoData] = useState({
    header: "",
    text: "",
  });

  const [activeNewsbar, setActiveNewsbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changeDisplayNameOpen, setChangeDisplayNameOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [loginHandlerState, setLoginHandlerState] = useState("login");
  const [orientation, setOrientation] = useState("");
  const [loadingText, setLoadingText] = useState("");

  const screenTransition = 800;
  const adminEmail = "all.immo.at@gmail.com";
  const searchProfilesLeft = customer?.maxSearchProfiles - customerSearches.length;


  useEffect(() => {
    window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
      const portrait = e.matches;
      portrait ? setOrientation("portrait") : setOrientation("landscape");
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <StateContext.Provider
      value={{
        screenSize,
        screenTransition,
        isLoading,
        customerObjects,
        activeNewsbar,
        customer,
        customerSearches,
        selectedObject,
        countries,
        customerChats,
        changeDisplayNameOpen,
        loginOpen,
        infoOpen,
        infoData,
        adminEmail,
        searchProfilesLeft,
        loginHandlerState,
        updates,
        matches,
        maintenanceInfo,
        affiliate,
        orientation,
        loadingText,
        searchesContacts,
        setSearchesContacts,
        changeLanguage,
        setMatches,
        setLoadingText,
        setAffiliate,
        setMaintenanceInfo,
        setUpdates,
        setLoginHandlerState,
        setInfoOpen,
        setInfoData,
        setLoginOpen,
        setChangeDisplayNameOpen,
        setCustomerChats,
        setSelectedObject,
        setCustomerSearches,
        setCustomer,
        setActiveNewsbar,
        setScreenSize,
        setIsLoading,
        setCustomerObjects,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
