import { CircularProgress, Collapse } from "@mui/material";
import { arrayRemove, arrayUnion } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useCreationContext } from "../../../contexts/CreationProvider";
import { storage } from "../../../firebase.config";
import { updateFirestoreDoc } from "../../../helper/functions";
import { Delete } from "../../../helper/icons";
import NavMenu from "../../Generics/NavMenu";
import StageLabel from "./StageLabel";
import { useNavigationContext } from "../../../contexts/NavigationProvider";

const Pics = ({ objectID }) => {
  const { screenSize } = useStateContext();
  const { handleMenuState } = useNavigationContext();
  const { uploadedFiles, setUploadedFiles } = useCreationContext();

  const [progressPercentPics, setProgressPercentPics] = useState(0);
  const [files, setFiles] = useState([]);
  const [exposeError, setExposeError] = useState(false);
  const [deletingPic, setDeletingPic] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(uploadedFiles.length > 0) {
      setFiles([]);
      setIsUploading(false);
      // setUploadedFiles([]);
    }
  }, [uploadedFiles]);  

  const handlePictureSubmit = async () => {
    files?.forEach((file) => {
      if (!file) return;
      if (file.size > 1024 * 1024 * 5) return;
      if (uploadedFiles.length + files.length > 10) return;

      setIsUploading(true);

      const storageRef = ref(
        storage,
        `objects/${objectID}/pictures/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgressPercentPics(progress);
        },
        (error) => console.log(error),
        async () => {
          getDownloadURL(uploadTask.snapshot.ref)
          .then(async (downloadURL) => {
            await updateFirestoreDoc("objects", objectID, { pictureLinks: arrayUnion({url: downloadURL, path: file.name})});
          });
          setTimeout(() => {
            setProgressPercentPics(0);
            setUploadedFiles([...uploadedFiles, ...files]);
          }, "500");

        }
      );
    });

  };

  const deletePicture = async (name) => {
    setDeletingPic(name);
    const picRef = ref(storage, `objects/${objectID}/pictures/${name}`);
    await getDownloadURL(picRef).then(async (picUrl) => {
      await updateFirestoreDoc("objects", objectID, { pictureLinks: arrayRemove({url: picUrl, path: name})})
      .then(async () => {
        deleteObject(picRef)
        setUploadedFiles(uploadedFiles.filter((el) => el.name !== name));
        setDeletingPic("");
      });
    })
    
  };

  const convertSize = (sizeToConvert) => {
    const sizeInMb = sizeToConvert / 1024 ** 2;
    const sizeInKb = sizeToConvert / 1024;
    if (sizeInMb > 1) {
      return sizeInMb.toFixed(2) + "Mb";
    } else {
      return sizeInKb.toFixed(2) + "Kb";
    }
  };

  return (
    <div className="flex-flex-col justify-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
      <StageLabel
        headline={t("General.Fotos")}
        iconSize={screenSize.width > 500 ? 70 : 60}
        color={false}
        stageIcon={require("../../../assets/animations/objectCreation/pics.json")}
        labelText={t("CreateObject.PicsLabel")}
      />

      <div className="flex flex-col items-center justify-center">
        <p
          className={`w-full text-center ${
            files.length > 10 - uploadedFiles.length
              ? "text-red-600 font-bold"
              : ""
          }`}
        >{`${t("Upload.FotosHochladen")}: ${files.length}/${
          10 - uploadedFiles.length
        }`}</p>
        <p className="w-full text-center -mt-1 text-sm text-allimmoDark/70 italic">
          {t("Upload.FotoLimits")}
        </p>
        <Collapse className="w-full my-3" in={files.length > 0}>
          <div className="flex flex-col justify-center items-center gap-1">
            {files?.map((p, i) => {
              return (
                <div
                  key={i}
                  className=" w-full bg-white p-1 rounded border border-orange-500 border-dashed flex flex-row justify-center items-center gap-3 h-16"
                >
                  <a
                    className="hover:opacity-50 h-full overflow-hidden flex flex-row justify-center items-center"
                    href={URL.createObjectURL(p)}
                    target="_blank"
                  >
                    <img src={URL.createObjectURL(p)} width={50} />
                  </a>
                  <div className="flex flex-col justify-center grow">
                    <p className="text-allimmoDark grow">
                      {p?.name.substring(
                        0,
                        screenSize.width < 390
                          ? 20
                          : screenSize.width < 390
                          ? 25
                          : 30
                      ) + "..."}
                    </p>
                    <p className="text-orange-500 italic -mt-1 text-sm">
                      {convertSize(p?.size)}
                    </p>
                    {p.size > 1024 * 1024 * 5 && (
                      <p className="font-bold text-red-600 -mt-1 italic">
                        {"--> Datei zu groß"}
                      </p>
                    )}
                  </div>

                  {!isUploading && (
                    <p
                      className="text-3xl text-red-400 cursor-pointer hover:text-red-300 active:text-red-400 w-6 text-center"
                      onClick={() =>
                        setFiles(files.filter((el) => el.name !== p?.name))
                      }
                    >
                      x
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </Collapse>
        {isUploading ? (
          <div className="flex flex-row justify-center w-full p-1">
            <CircularProgress
              variant="determinate"
              value={progressPercentPics}
              size={50}
              color="success"
            />
          </div>
        ) : (
          <div className="flex h-12 flex-row items-center justify-center w-full gap-1">
            <input
              multiple
              id="upload"
              hidden
              onChange={(e) =>
                setFiles([...files, ...Array.from(e.target?.files)])
              }
              type="file"
              accept=".jpg, .png, .jpeg"
            />
            <label
              className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100"
              htmlFor="upload"
            >
              <p className="text-center w-full text-allimmoDark ssm:text-lg">
                Dateien auswählen
              </p>
            </label>
            {files.length > 0 &&
              progressPercentPics === 0 &&
              !files.some((el) => el.size > 1024 * 1024 * 5) && (
                <button
                  onClick={handlePictureSubmit}
                  className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100"
                >
                  <p className="text-center w-full text-allimmoDark ssm:text-lg">
                    Dateien hochladen
                  </p>
                </button>
              )}
            {exposeError && (
              <p className="text-red-500 text-sm bg-red-100 mt-1 px-1">
                {t("Upload.Error")}
              </p>
            )}
          </div>
        )}
      </div>
      <div className="flex flex-row items-center mt-2">
        <p className="grow">hochgeladene Fotos:</p>
        <p>{uploadedFiles.length}/10</p>
      </div>

      <div className="grid grid-cols-3 gap-1">
        {uploadedFiles.map((f, i) => {
          return (
            <div
              key={i}
              className="flex flex-col justify-center items-center bg-white h-100 w-80"
            >
              {deletingPic === f.name ? (
                <div className="p-5">
                  <CircularProgress size={30} color="error" />
                </div>
              ) : (
                <div className="h-full overflow-hidden relative border border-black">
                  <a
                    className="hover:opacity-50"
                    href={URL.createObjectURL(f)}
                    target="_blank"
                  >
                    <img src={URL.createObjectURL(f)} width={80} />
                  </a>
                  <div className="grow" />
                  <div
                    onClick={() => deletePicture(f.name)}
                    className="hover:text-red-300 opacity-80 hover:bg-red-100 cursor-pointer w-full absolute bottom-0 bg-red-200 mt-0.5 p-0.5 flex flex-row justify-center items-center"
                  >
                    <Delete className="text-red-500" size={20} />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <NavMenu backBtn={false} readyBtn _ready={() => handleMenuState("objects")}/>
    </div>
  );
};

export default Pics
