import { Checkbox, CircularProgress, Collapse, FormControlLabel, MenuItem, TextField } from '@mui/material';
import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import { updateProfile, verifyAffiliateCode } from '../../helper/functions';
import Button from '../Generics/Button';
import validator from 'validator';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import de from 'i18n-iso-countries/langs/de.json';
import PhoneInput from 'react-phone-input-2';
import dePhone from 'react-phone-input-2/lang/de.json';
import VerificationInput from 'react-verification-input';

const CompleteProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [affiliateLoading, setAffiliateLoading] = useState(false);

    const [data, setData] = useState({
        name: "",
        phone: "",
        yearOfBirth: "",
        gender: "-",
        code: "",
        income: "-",
        education: "-",
        company: false,
        agb: false,
        employment: "-",
        country: "AT",
        presentZip: "",
    });
    const [notFound, setNotFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [verificationResult, setVerificationResult] = useState("no-result");

    const lang = i18next.resolvedLanguage === "de" ? "de" : "en";

    useEffect(() => {
      if(data.code.length === 6) {
        startCodeVerification();
      } else {
        setVerificationResult("no-result");
      }
    }, [data.code]);

    const startCodeVerification = async () => {
      setAffiliateLoading(true);
      try {
        const verification = await verifyAffiliateCode({code: data.code});
        setVerificationResult(verification.data?.valid);
        setAffiliateLoading(false);
      } catch (error) {
        console.error(error.message);
        setAffiliateLoading(false);
      }
    };
    
    countries.registerLocale(de);
    countries.registerLocale(en);

    const countryList = Object.keys(countries.getAlpha2Codes()).map((code) => ({
      code,
      name: countries.getName(code, lang),
      flag: `https://flagcdn.com/w320/${code.toLowerCase()}.png`,
    }));

    const saveData = async () => {
        setErrorMessage("");
        if(data.yearOfBirth.length > 0) {
          if (data.yearOfBirth.trim().length !== 4 || !validator.isNumeric(data.yearOfBirth.trim())) {
            return setErrorMessage("yearOfBirth-invalid");
          }
        }

        setIsLoading(true);
        const updateResult = await updateProfile({
          profileData: {
            agbAccepted: data.agb,
            company: data.company,
            country: data.country,
            education: data.education,
            employment: data.employment,
            gender: data.gender,
            income: data.income,
            name: data.name.trim(), 
            phone: data.phone.trim(),
            presentZip: data.presentZip.trim(),
            yearOfBirth: data.yearOfBirth.trim(),
          },
          code: data.code, 
        });
        if(updateResult.data?.result === "not-found") {
          setNotFound(true);
        } else {
          setNotFound(false);
        }
        setIsLoading(false);
    };

    const agbText = () => {
      const handleAgbClick = (e) => {
        e.stopPropagation()
        window.open("https://www.allimmo.at/agb/", "_blank");
      };

      return (
        <div className='flex flex-col ssm:flex-row items-center ssm:gap-1 mt-1 ssm:mt-0'>
          <p className={`text-sm italic`}>{t("CompleteProfile.AGBLinkText")}</p>
          <p onClick={(e) => handleAgbClick(e)} className="text-sm text-allimmoDark underline italic">{t("CompleteProfile.AGBLink")}</p>
        </div>
      )
    };

    const handleCodeInput = (value) => {
      setData({...data, code: value});
      return;
    };
    
  return (
    <div className='py-5 px-1 sm:px-2 ssm:px-5 flex flex-col justify-center items-center select-none w-full self-center md:w-2/3 lg:w-1/2'>
        {/* <p className='bg-orange-50 text-orange-500 rounded px-1 ssm:px-3 text-sm text-center mb-3'>{t('CompleteProfile.InfoText')}</p> */}
        <img src="https://firebasestorage.googleapis.com/v0/b/move-58c8d.appspot.com/o/allgemein%2FAllimmomatch.png?alt=media&token=e6afb35b-6fb7-46dd-831f-4dd1b233b598" alt="ALLIMMO MATCH" width={150} />
        <p className='ssm:text mb-6 w-full text-center px-3'>{t('Account.ProfilePopupHeader')}</p>
        <TextField error={data.name.length > 0 && data.name.length < 5} required size='small' label={t('Account.IhrName')} className="w-full" value={data.name} onChange={(e) => setData({...data, name: e.target.value})}/>
        <p className={`text-xs w-full ml-1 italic mb-3 ${(data.name.length > 0 && data.name.length < 5) ? "text-red-500 animate-pulse" : ""}`}>{t('Account.NameHelper')}</p>

        <TextField required value={data.company} onChange={(e) => setData({...data, company: e.target.value})} className='w-full' select size='small' label={t('Kontotypen.Label')}>
            <MenuItem value={false}>{t("Kontotypen.Privat")}</MenuItem>
            <MenuItem value={true}>{t("Kontotypen.Firma")}</MenuItem>
        </TextField>
        
        <div className='mt-2 flex flex-col gap-2 items-center justify-center w-full border-2 border-dashed rounded px-2 pt-2 pb-2 border-allimmoDark/60'>
          <p className='text-allimmoDark/80 text-xs text-left w-full bg-allimmoDark/10 rounded p-1'>{t('Account.AdditionalDataInfo')}</p>
          <p className='text-orange-400 -mt-1.5 text-xs text-left w-full bg-orange-100 rounded p-1'>{t('Account.ProfileCompleteInfo')}</p>
          {/* <TextField size='small' label={t('Account.Telefonnummer')} className="w-full" value={data.phone} onChange={(e) => setData({...data, phone: e.target.value})}/> */}
          <div className='flex flex-col mmd:flex-row gap-2 w-full'>
            <PhoneInput
              preferredCountries={['at', 'de', 'ch']}
              autoFormat={false}
              countryCodeEditable={false}
              inputStyle={{width: '100%', height: '40px'}}
              country='at'
              value={data.phone}
              localization={lang === "de" ? dePhone : undefined}
              onChange={phone => setData({...data, phone: "+" + phone})}
              inputProps={{
                required: true
              }}
            />
            <TextField value={data.gender} onChange={(e) => setData({...data, gender: e.target.value})} className='w-full' select size='small' label={t('Account.Geschlecht')}>
              <MenuItem value="-">{'-'}</MenuItem>
              <MenuItem value="male">{t('Account.Male')}</MenuItem>
              <MenuItem value="female">{t('Account.Female')}</MenuItem>
              <MenuItem value="divers">{t('Account.Divers')}</MenuItem>
            </TextField>
          </div>
          
          <div className='flex flex-row gap-2 w-full'>          
            <TextField type='number' size='small' label={t('Account.Geburtsjahr')} className="w-full" value={data.yearOfBirth} onChange={(e) => setData({...data, yearOfBirth: e.target.value})} InputProps={{minLength: 4, maxLength: 4}}/>
            <TextField type='number' size='small' label={t('Account.PresentZip')} className="w-full" value={data?.presentZip} onChange={(e) => setData({...data, presentZip: e.target.value})} InputProps={{minLength: 4, maxLength: 5}}/>
          </div>

          {!data.company &&
          <div className='flex flex-col mmd:flex-row gap-2 w-full'>
            <TextField value={data.income} onChange={(e) => setData({...data, income: e.target.value})} className='w-full' select size='small' label={t("Account.Nettoeinkommen")}>
              <MenuItem value="-">{'-'}</MenuItem>
              <MenuItem value="1m">{t('Income.1m')}</MenuItem>
              <MenuItem value="13">{t('Income.13')}</MenuItem>
              <MenuItem value="35">{t('Income.35')}</MenuItem>
              <MenuItem value="5p">{t('Income.5p')}</MenuItem>
            </TextField>

            <TextField value={data.employment} onChange={(e) => setData({...data, employment: e.target.value})} className='w-full' select size='small' label={t("Account.Arbeitsverhältnis")}>
              <MenuItem value="-">{'-'}</MenuItem>
              <MenuItem value="employed">{t('Arbeitsverhältnis.employed')}</MenuItem>
              <MenuItem value="self">{t('Arbeitsverhältnis.self')}</MenuItem>
              <MenuItem value="retired">{t('Arbeitsverhältnis.retired')}</MenuItem>
              <MenuItem value="training">{t('Arbeitsverhältnis.training')}</MenuItem>
            </TextField>
          </div>}
          
          <div className='flex flex-col mmd:flex-row gap-2 w-full'>
            <TextField value={data.education} onChange={(e) => setData({...data, education: e.target.value})} className='w-full' select size='small' label={t("Account.Ausbildung")}>
              <MenuItem value="-">{'-'}</MenuItem>
              <MenuItem value="lehre">{t('Ausbildung.lehre')}</MenuItem>
              <MenuItem value="matura">{t('Ausbildung.matura')}</MenuItem>
              <MenuItem value="degree">{t('Ausbildung.degree')}</MenuItem>
              <MenuItem value="special">{t('Ausbildung.special')}</MenuItem>
            </TextField>
            
            <TextField value={data.country} onChange={(e) => setData({...data, country: e.target.value})} className='w-full' select size='small' label={t("CompleteProfile.Herkunftsland")}>
              {countryList.sort((a,b) => a?.name.localeCompare(b?.name)).map((country, index) => {
                return (
                  <MenuItem key={index} value={country.code}>
                    <div className='flex flex-row items-center gap-2'>
                      <img src={country.flag} alt={country.name} className='w-6 h-5 border border-black rounded'/>
                      <p>{country.name}</p>
                    </div>
                  </MenuItem>
                )
              })}
            </TextField>
          </div>
        </div>
        <div className='w-full flex flex-row items-center'>
          <FormControlLabel
              control={
                <Checkbox
                  value={data?.agb}
                  checked={data?.agb}
                  onChange={() => setData({...data, agb: !data?.agb})}
                />
              }
              label={agbText()}
          />
        </div>
        <div className='flex flex-col gap-2 items-center justify-center w-full mt-3'>
          <VerificationInput 
            onChange={handleCodeInput} 
            value={data.code}
            placeholder='' 
            classNames={{
              characterInactive: "bg-white opacity-20",
              characterSelected: `${verificationResult === "no-result" ? "bg-[#f6f5fa]" : verificationResult === false ? "border-red-500 bg-red-50" : "border-green-500 bg-green-50"} after:content-['|'] after:animate-pulse after:animate-duration-[800ms]`,
              character: `border-allimmoDark/70 border-2 outline-none text-[20px] rounded-[8px] text-[#272729] ${verificationResult === "no-result" ? "bg-[#f6f5fa]" : verificationResult === false ? "border-red-500 bg-red-50" : "border-green-500 bg-green-50"}`,
            }} 
            length={6}
          />
          {verificationResult === true && <p className='text-green-500 text-sm -mt-1'>Code gültig</p>}
          {verificationResult === false && <p className='text-red-500 text-sm -mt-1'>Code ungültig</p>}
          {/* <TextField autoComplete='off' size='small' label={t('Account.AffiliateCode')} className="w-full" value={data.code} onChange={(e) => handleCodeInputChange(e.target.value)}/> */}
          {verificationResult === "no-result" && <p className='w-full italic text-sm text-center'>{t('Affiliate.EinzigeMöglichkeit')}</p>}
          <Collapse in={notFound}>
            <p className={`w-full italic text-red-500 text-center`}>{t('Affiliate.NichtGefunden')}</p>
          </Collapse>
        </div>
        <div className='flex flex-col space-x-2 mt-5'>
            <Button loading={isLoading} isDisabled={data?.name?.trim().length < 5 || data?.agb === false || (data.code.length > 0 && verificationResult === "no-result")} clickFunction={saveData} text={t('General.Speichern')} />
            {errorMessage !== "" && <p className='text-red-400 italic mt-1'>{t(`ErrorMessages.${errorMessage}`)}</p>}
        </div>
    </div>
  )
}

export default CompleteProfile
