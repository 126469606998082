import { LinearProgress } from '@mui/material';

const CreationProgressbar = ({val, visible}) => {
  return (
    // <div className={`w-300 bg-transparent ssm:w-400 transition-opacity duration-[1500ms] ${visible ? "opacity-100" : "hidden opacity-0"} h-20 z-50 fixed bottom-10 ssm:bottom-16 animate-duration-1000 animate-fade-down flex flex-row gap-2 justify-center items-center`}>
    //     <LinearProgress
    //     sx={{ border: "1px dashed #212121", width: "80%", height: "40px", borderRadius: "10px", '& .MuiLinearProgress-bar': { backgroundColor: '#00547B50' }, "&.MuiLinearProgress-root": { backgroundColor: "#eeeeee" } }}
    //      className='animate-float animate-infinite' 
    //      color='success' value={val} variant='determinate'/>
    // </div>
    <div className={`w-300 bg-transparent ${visible ? "block" : "hidden"} ssm:w-400 h-20 z-50 animate-duration-1000 animate-fade-down flex flex-row gap-2 justify-center items-center`}>
        <LinearProgress
        sx={{ border: "1px dashed #212121", width: "80%", height: "40px", borderRadius: "10px", '& .MuiLinearProgress-bar': { backgroundColor: '#00547B50' }, "&.MuiLinearProgress-root": { backgroundColor: "#eeeeee" } }}
        color='success' value={val} variant='determinate'/>
    </div>
  )
}

export default CreationProgressbar
