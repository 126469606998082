import { collection, onSnapshot, query, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebase.config";

const MatchesContext = createContext();

export const MatchesProvider = ({ children }) => {
    const [matches, setMatches] = useState([]);

    useEffect(() => {
        const matchesQuery = query(collection(firestore, "matches"), where("objectUser", "==", auth.currentUser.uid), where("status", "==", "matched"));
        const unsubMatches = onSnapshot(matchesQuery, (result) => {
            setMatches(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
        });

        return () => {
            unsubMatches();
        }
    }, []);
    

    return (
        <MatchesContext.Provider
            value={{ 
                matches,
                setMatches
            }}>
                {children}
        </MatchesContext.Provider>
    )
};

export const useMatchesContext = () => useContext(MatchesContext)
