import { collection, onSnapshot, query, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebase.config";

const ObjectContactsContext = createContext();

export const ObjectContactsProvider = ({ children }) => {
    const [objectContacts, setObjectContacts] = useState([]);

    useEffect(() => {
        const objectsContactsQuery = query(collection(firestore, "contacts"), where("customer", "==", auth.currentUser.uid));
        const unsubObjectsContacts = onSnapshot(objectsContactsQuery, (result) => {
            setObjectContacts(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
        });

        return () => {
            unsubObjectsContacts();
        }
    }, []);
    

    return (
        <ObjectContactsContext.Provider
            value={{ 
                objectContacts,
                setObjectContacts,
            }}>
                {children}
        </ObjectContactsContext.Provider>
    )
};

export const useObjectContactsContext = () => useContext(ObjectContactsContext)
