import { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebase.config";
import { collection, onSnapshot, query, where } from "firebase/firestore";

const CreditTransactionsContext = createContext();

export const CreditTransactionsProvider = ({ children }) => {
    const [creditTransactions, setCreditTransactions] = useState([]);

    useEffect(() => {
        const creditTransactionsQuery = query(collection(firestore, "creditTransactions"), where("customer", "==", auth.currentUser.uid));
        const unsubcreditTransactions = onSnapshot(creditTransactionsQuery, (result) => {
            setCreditTransactions(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
        });
        
        return () => unsubcreditTransactions();
    }, []);

    return (
        <CreditTransactionsContext.Provider
            value={{ 
                creditTransactions,
                setCreditTransactions
            }}>
                {children}
        </CreditTransactionsContext.Provider>
    )
};

export const useCreditTransactionsContext = () => useContext(CreditTransactionsContext)
