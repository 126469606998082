import { t } from 'i18next';
import AllimmoMatchLogo from '../../assets/AllimmoMatchLogo.png';
import { useState } from 'react';
import { Dot } from '../../helper/icons';
import { addFirestoreColl, updateFirestoreDoc } from '../../helper/functions';
import { auth } from '../../firebase.config';
import Page1 from '../Onboarding/Page1';
import Page2 from '../Onboarding/Page2';
import Page3 from '../Onboarding/Page3';
import Page4 from '../Onboarding/Page4';
import Page5 from '../Onboarding/Page5';
import i18n from '../../i18n';
import { useStateContext } from '../../contexts/ContextProvider';
import Page0 from '../Onboarding/Page0.';
import { Timestamp } from 'firebase/firestore';
import { usePopupContext } from '../../contexts/PopupProvider';
import Page6 from '../Onboarding/Page6';

const Onboarding = () => {

  const { changeLanguage, customer } = useStateContext();
  const { setOnboardingOpen } = usePopupContext();

  const [stage, setStage] = useState(0);
  const [interfaceContact, setInterfaceContact] = useState(false);

  const stages = [0, 1, 2, 3, 4, 5, 6];

  const finishOnboarding = async () => {
    try {
      if(!auth.currentUser || customer?.onboarding) {
        setOnboardingOpen(false);
      } else {
        await updateFirestoreDoc('customers', auth.currentUser.uid, { onboarding: true });
        if(interfaceContact) {
          console.log("interface contact");
          await addFirestoreColl("messages", {
              subject: "Schnittstellenintegration",
              message: "Bitte um Kontaktaufnahme bezüglich Schnittstellenintegration auf Ihrer Palttform.",
              email: auth.currentUser ? auth.currentUser.email : "",
              name: customer?.name ?? "",
              created: Timestamp.fromDate(new Date()),
              customer: auth.currentUser ? auth.currentUser.uid : "anonym",
          });
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const nextStage = () => {
    if (stage < stages.length - 1) {
      setStage(stage + 1);
    }
  };

  return (
    <div className='w-full gap-5 ssm:gap-5 flex flex-col items-center pt-4 pb-8 px-2'>
      <div className='flex flex-col items-center'>
        <img className='mb-2 sm:mb-3 w-90 sm:w-120' src={AllimmoMatchLogo} alt='ALLIMMO'/>
        <p className='text-allimmoDark text-lg sm:text-xl border-t border-allimmoDark/70 pt-1 sm:pt-2'>{t('Onboarding.Header')}</p>
        <p className='text-allimmoDark/60 text-sm italic -mt-1'>{t('Onboarding.Sub')}</p> 
      </div>
      <div className='flex flex-row gap-1 ssm:mb-3 -mt-3'>
        <img onClick={() => changeLanguage("de")} src={require('../../assets/ger.png')} alt='german' className={`${i18n.resolvedLanguage === "en" ? "opacity-20" : ""} w-5 h-4 cursor-pointer hover:opacity-100`}/>
        <img onClick={() => changeLanguage("en")} src={require('../../assets/eng.png')} alt='english' className={`${i18n.resolvedLanguage === "de" ? "opacity-20" : ""} w-5 h-4 cursor-pointer hover:opacity-100`}/>
      </div>
      <div className='flex flex-col justify-center items-center max-w-600'>
        {stage === 0 && <Page1 />}
        {stage === 1 && <Page0 changeInterfaceContact={() => setInterfaceContact(!interfaceContact)} contactInterface={interfaceContact}/>}
        {stage === 2 && <Page2 />}
        {stage === 3 && <Page3 />}
        {stage === 4 && <Page4 />}
        {stage === 5 && <Page6 />}
        {stage === 6 && <Page5 />}
      </div>
      
      <div className='flex flex-row justify-center gap-2 ssm:pb-5'>
        {stages.map((s, i) => {
          return (
            <Dot onClick={() => setStage(i)} size={24} className={`${stage === i ? "text-orange-300" : "text-allimmoDark/80"} cursor-pointer hover:opacity-50`} key={i} />
          )
        })}
      </div>
      <div className='flex flex-row items-center justify-center gap-3'>
        {stage !== 6 && <p onClick={nextStage} className='cursor-pointer border border-allimmoDark rounded px-4 text-allimmoDark bg-white hover:bg-allimmoDark/5'>{t('Onboarding.Weiter')}</p>}
        <p onClick={finishOnboarding} className='cursor-pointer border border-gray-400 rounded px-4 text-gray-400 bg-white hover:text-gray-900 hover:bg-gray-100 hover:border-gray-700'>{t('Onboarding.Abschluss')}</p>
      </div>
    </div>
  )
};

export default Onboarding
