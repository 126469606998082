import { useEffect, useState } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { t } from 'i18next';
import Button from '../../Generics/Button';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { Transition } from '../../../helper/functions';
import AnimationIcon from '../../Generics/AnimationIcon';
import { calculateGeohashAndDepthForSearch } from '../../../helper/calculateGeohashAndDepthForSearch';
import SearchDepthSquares from './components/SearchDepthSquares';
import SearchCheckBox from './SearchCheckBox';
import { green } from '@mui/material/colors';

const Summary = ({back, _create, _reset}) => {

    const { searchData, resetSearchData, setSearchData } = useCreationContext();

    const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
    const [calculationData, setCalculationData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
        const result = calculateGeohashAndDepthForSearch(searchData);
        setCalculationData(result);
    }, []);

    useEffect(() => {
        calculationData && setSearchData({
            ...searchData, 
            depth: calculationData?.depth, 
            depthDetails: calculationData?.depthDetails,
            geohashes: calculationData?.geohashes,
        });
    }, [calculationData]);

    const handleClose = (event, reason) => {
        if (reason && event === "backdropClick") 
            return;
        setResetConfirmOpen(false);
    };

    const handleReset = () => {
        resetSearchData();
        setResetConfirmOpen(false);
        _reset();
    };

    const getNoLoanCheckboxText = () => {
        return (
            <div className='flex flex-row items-center italic gap-1 text-allimmoDark underline-offset-2'>
                <p>Ich benötige</p>
                <p className='underline'>keine</p>
                <p>Finanzierung</p>
            </div>
        )
    };

  return (
    <div className="flex flex-col justify-center items-center w-full md:w-1/2">
        <AnimationIcon iconSrc={require('../../../assets/animations/searchCreation/summary.json')} _iconSize={40} _color={false}/>
        <p className="text-allimmoDark text-xl font-bold text-center">{`Suchprofil ${t('CreateObject.Fertig')}`}</p>
        <p className="text-allimmoDark text-sm text-center italic">{`"${searchData?.name}"`}</p>
        {/* <p className="text-allimmoDark italic">{`${t('CreateSearch.WasTun')}`}</p> */}

        <div className='flex flex-col items-center bg-white shadow-xl justify-center py-3 px-5 my-3 border border-dashed border-allimmoDark/60 rounded-lg'>
            <p className='mb-2'>{t(`SearchDepth.Header`)}</p>

            <SearchDepthSquares value={calculationData?.depth} />

            <p className={`text-xl font-bold underline uppercase mt-1 ${calculationData?.depth === 3 ? "text-depthSehrUngenau" : calculationData?.depth === 4 ? "text-depthUngenau" : [7, 8].includes(calculationData?.depth) ? "text-depthGenau" : calculationData?.depth === 9 ? "text-depthSehrGenau" : "text-depthNormal"}`}>{t(`SearchDepth.${calculationData?.depth}`)}</p>
            {/* <p className='text-sm'>{`${calculationData?.depth} ${t("General.Von")} 9`}</p> */}
            <p className='text-sm mt-2 text-center text-gray-500'>{t(`SearchDepth.Info.${calculationData?.depth}`)}</p>
            <p className='text-sm mb-2 text-center text-gray-500'>{t(`SearchDepth.Info2.${calculationData?.depth}`)}</p>

            {calculationData?.depth < 7 &&
            <div className='text-13 italic flex flex-col justify-center border-t border-allimmoDark/20 pt-3 mt-2 w-full'>
                <p className='mb-1 underline'>{t(`SearchDepth.TippsHeader`)}</p>
                {calculationData?.depthDetails?.size?.depth < 3 && 
                <p>{`+ ${t('SearchDepth.Tipps.Wohnfläche')}`}</p>}
                {calculationData?.depthDetails?.location?.depth < 3 && 
                <p>{`+ ${t('SearchDepth.Tipps.Gebiet')}`}</p>}
                {calculationData?.depthDetails?.facilities?.depth < 3 &&
                <p>{`+ ${t('SearchDepth.Tipps.Ausstattung')}`}</p>}
                {(calculationData?.depthDetails?.facilities?.depth < 3 && searchData?.openAreas === "") &&
                <p>{`+ ${t('SearchDepth.Tipps.OpenAreas')}`}</p>}
            </div>}
        </div>
        
        {searchData?.deal === "sale" &&
        <div className='bg-white border border-allimmoDark/50 rounded flex cursor-pointer flex-row justify-center items-center pr-2.5' onClick={() => setSearchData({...searchData, noLoanNeeded: !searchData?.noLoanNeeded})}>
            <Checkbox
                disableRipple
                disableTouchRipple
                value={searchData?.noLoanNeeded ?? false}
                checked={searchData?.noLoanNeeded ?? false}
            />
            <p className="text-sm sm:text-base">
                {getNoLoanCheckboxText()}
            </p>
        </div>}
            
        <div className="flex flex-row mt-3">
            <SummaryButton delay={"1000"} clickFunction={() => _create(true)} label={t('CreateSearch.SucheAktivieren')} icon={require(`../../../assets/animations/searchCreation/saveandactivate.json`)}/>
            {/* <SummaryButton clickFunction={() => _create(false)} label={"Speichern OHNE Aktivierung"} icon={require(`../../../assets/animations/objectCreation/save.json`)}/> */}
        </div>

        <div className='w-full flex flex-row justify-center items-center mt-6'>
            <Button text={t('General.Zurück')} clickFunction={back}/>
        </div>
        <p className="italic text-sm hover:underline cursor-pointer mt-1 opacity-80" onClick={() => setResetConfirmOpen(true)}>{t('CreateSearch.Zurücksetzen')}</p>
    
        <Dialog onClose={(e, r) => handleClose(e,r)} open={resetConfirmOpen} fullWidth={false} TransitionComponent={Transition} className='select-none'>
            <div className='flex flex-col justify-center items-center py-5 px-8'>
                <p className='text-allimmoDark text-center w-full'>{t('CreateSearch.ResetLabel')}</p>
                <p className='mb-2 text-red-600 italic text-sm w-full text-center'>{t('CreateSearch.ResetSub')}</p>
                <div className='w-full flex flex-row justify-center items-center gap-2 mt-5'>
                    <Button text={t('General.Abbrechen')} clickFunction={() => setResetConfirmOpen(false)}/>
                    <Button text={t('General.Zurücksetzen')} clickFunction={handleReset}/>
                </div>
            </div>
        </Dialog>
    </div>
  )
};

const SummaryButton = ({label, icon, clickFunction}) => {
    const { screenSize } = useStateContext();

    return (
        <div onClick={clickFunction} className={`flex flex-col hover:bg-blue-100 bg-white hover:shadow-lg cursor-pointer justify-center items-center border-2 border-allimmoDark p-1 w-180 ${screenSize.width > 768 ? "h-26" : screenSize.width > 355 ? "h-20" : "h-20"} rounded-xl`}>
            <AnimationIcon _color={false} _iconSize={70} iconSrc={icon}/>
            <p className='text-allimmoDark w-full text-center text-12 ssm:text-sm md:text-base'>{label}</p>
        </div>
    )
};

export default Summary
