import React from 'react'
import { EmailSign, PersonCircle } from '../../helper/icons'
import { t } from 'i18next'
import { useStateContext } from '../../contexts/ContextProvider';
import { convertShortTimestamp, hideString } from '../../helper/functions';
import { CircularProgress } from '@mui/material';
import { useSelectionContext } from '../../contexts/SelectionProvider';
import { depthTextColors } from '../../helper/arrays';

const Contacted = ({ _activeContacts }) => {

    const { screenSize } = useStateContext();
    const { setChatSelection, setChatDetailsOpen } = useSelectionContext();

    const handleChatOpen = (chatId) => {
        setChatSelection(chatId);
        setChatDetailsOpen(true);
    };

    const sortedData = _activeContacts.sort((a, b) => {
        if (a?.status === "accepted" && b?.status !== "accepted") {
          return -1; // a comes before b
        }
        if (a?.status !== "accepted" && b?.status === "accepted") {
          return 1; // b comes before a
        }
        return 0; // no change in order
      });

    return (
        <div className='flex flex-col items-center w-full max-h-56 overflow-auto border border-allimmoDark p-1 rounded'>
            {sortedData.map((contactData, i) => {
            return (
                <div key={i} className={`flex flex-col items-center w-full px-1 ${i !== 0 ? 'mt-1' : ''} p-1 border border-allimmoDark/30 rounded`}>
                    <div className={`flex flex-row items-center w-full`}>
                        <PersonCircle className={`mr-1 sm:mr-2 ${depthTextColors[contactData?.searchDepth]}`} size={18}/>
                        <p className={`grow ${contactData?.status === "accepted" ? "" : "blur-[2.25px]"}`}>{contactData?.status === "accepted" ? contactData?.searchUser : screenSize.width > 450 ? "======" : "==="}</p>
                        {(contactData.status === "pending" && screenSize.width > 768) && 
                        <p className='mr-2 md:mr-5 text-sm text-center italic text-red-600'>{`${t('ObjectDetails.LäuftAb')} ${convertShortTimestamp(contactData?.timeout.toDate())}`}</p>}
                        <div className='flex flex-row gap-1 mx-3 md:mx-5 text-sm italic text-center'>
                            <p>{screenSize.width < 500 ? t('ObjectDetails.KontaktiertAmShort') : t('ObjectDetails.KontaktiertAm')}</p>
                            <p>{convertShortTimestamp(contactData?.created.toDate())}</p>
                        </div>
                        {/* <Rating rating={(getSearchRating(data) * 10).toFixed(1)}/> */}
                        {/* {contactData.chat !== "" && showChatBtn && <Button text={t('SearchDetails.Chat')} loading={contactData?.chat === ""} clickFunction={(e) => handleChatOpen(e)}/>} */}
                        <div className='w-16 ssm:w-20 mr-1 flex flex-row justify-end'>
                            {(contactData?.chat !== "" && contactData?.status === "accepted") && <p className='hover:underline cursor-pointer text-allimmoDark text-sm' onClick={() => handleChatOpen(contactData?.chat)}>{t('ObjectDetails.Contacted.ZumChat')}</p>}
                            {contactData?.status === "pending" && <CircularProgress size={17}/>}
                        </div>
                    </div>
                    {contactData?.searchUserData &&
                    <div className='flex flex-row items-center text-sm italic w-full'>
                        <EmailSign className={`mr-1 sm:mr-2 text-allimmoDark`} size={18}/>
                        <p className='select-all'>{contactData?.searchUserData?.email}</p>
                        {contactData?.searchUserData?.phone !== "" &&
                        <>
                            <p className='mx-3'>|</p>
                            <p className='select-all'>{contactData?.searchUserData?.phone}</p>
                        </>}
                    </div>}
                </div>
                )
            })}
        </div>
    )
};

export default Contacted
